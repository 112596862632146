import {
  ApiResponse,
  AssignedProductApi,
  BenefitInfo,
  CallBarsApi,
  ComponentIdentifierApi,
  ComponentIdentifierParameter,
  CustomerProductType,
  DashboardApi,
  CallBars as ICallBars,
  IWifiRoamingStatus,
  InternetSecurityApi,
  InternetSecurityLink,
  PhoneSettings,
  PhoneSettingsApi,
  ProductType,
  Products,
  SecretCircleApi,
  TelevisionApi,
  TvBenefit,
  TvBenefitEligibilityEnum,
  WifiSpot,
  WifiSpotApi,
  WifiSpotStatus,
} from 'api';
import { CoBrowsingApi } from 'api/cobrowse.api';
import { TelephonyApi } from 'api/telephoney.api';
import { AuthContext } from 'context/auth/auth.context';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { get } from 'lodash';
import { mergeDeepLeft, mergeDeepRight } from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';
import UserUtil from 'utils/user.util';

export interface IProductContext {
  products: Products | null;
  isLoading: boolean;
  callBars: CallBars | null;
  hasError: boolean;
  isCallBarsLoading: boolean;
  eligibleBenefits: TvBenefit[] | null;
  isBenefitsLoading: boolean;
  hasBenefitsError: boolean;
  isTvLoading: boolean;
  isInternetLoading: boolean;
  isTelephonyLoading: boolean;
  isCobrowseSession: boolean;
  hasDashboardProductsLoaded: boolean;
  hasOverviewLoaded: boolean;
  hasTvDetailsLoaded: boolean;
  hasInternetDetailsLoaded: boolean;
  hasTelephonyDetailsLoaded: boolean;
  hasAllBenefits: boolean;
  hasInternetDetailError: boolean;
  secretCircleLocked?: boolean;
  canChangeSecretCircle: boolean;
  hasCableTV: boolean;
  hasDigitalTV: boolean;
  hasTv: () => boolean;
  hasInternet: () => boolean;
  hasPhone: () => boolean;
  hasProducts: () => boolean;
  hasGroupProduct: () => boolean;
  getOverviewProducts: () => void;
  getDetailedProducts: (type: ProductType) => void;
  getCallBars: (id: string[]) => Promise<void>;
  postCallBars: (telephonyProductId: string, callBars: string, existingCallBars: string) => Promise<boolean>;
  getEligibleBenefits: () => Promise<void>;
  getCobrowseSession: () => void;
  getPhoneSettings: (id: string) => Promise<PhoneSettings>;
  postPhoneSettings: (id: string, settings: PhoneSettings) => Promise<void>;
  canUpdatePhoneSettings: (id: string) => Promise<boolean>;
  changeNumber: (id: string) => Promise<any>;
  getWifiRoamingStatus: () => Promise<IWifiRoamingStatus | null>;
  getInternetSecurityRedirect: (internetProductId: string) => Promise<InternetSecurityLink>;
  canGetWifiSpot: () => Promise<boolean>;
  canChangeWifiSpot: () => Promise<boolean>;
  changeWifiSpot: (status: WifiSpotStatus) => Promise<WifiSpot>;
  resetWifiSpotPassword: () => Promise<WifiSpot>;
  getWifiSpot: () => Promise<WifiSpot>;
  canChangeNumber: (assignedProductId: string) => Promise<boolean>;
  canUpdateCallBars: (telephonyProductId: string) => Promise<boolean>;
  canRefreshEntitlements: (productId: string, receiverId: string) => Promise<boolean>;
  refreshEntitlements: (productId: string, receiverId: string) => ApiResponse<{ success: boolean }>;
  resetPin: (receiverId: string) => ApiResponse<{ success: boolean }>;
  canResetPin: (receiverId: string) => Promise<boolean>;
  toggleSecretServiceLockState: (enabled: boolean) => Promise<boolean>;
  getSecretCircle: () => Promise<void>;
  productType: string | null;
  isConverged: boolean;
}

interface ProductProviderProps {
  children: React.ReactNode;
}

const ProductContext = createContext<IProductContext | undefined>(undefined);

const useProductContext = () => useContext(ProductContext) as IProductContext;

type CallBars = {
  [key: string]: ICallBars;
};

const ProductProvider: React.FC = ({ children }: ProductProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const authContext = useContext(AuthContext);
  const [products, setProducts] = useState<Products | null>(null);
  const [callBars, setCallBars] = useState<CallBars | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isCallBarsLoading, setIsCallBarsLoading] = useState(false);
  const [isTvLoading, setIsTvLoading] = useState(false);
  const [isInternetLoading, setIsInternetLoading] = useState(false);
  const [isTelephonyLoading, setIsTelephonyLoading] = useState(false);
  const [isCobrowseSession, setIsCobrowseSession] = useState(false);
  const [hasDashboardProductsLoaded, setDashboardProductsLoaded] = useState(false);
  const [hasOverviewLoaded, setOverviewLoaded] = useState(false);
  const [hasTvDetailsLoaded, setHasTvDetailsLoaded] = useState(false);
  const [hasInternetDetailsLoaded, setHasInternetDetailsLoaded] = useState(false);
  const [hasTelephonyDetailsLoaded, setHasTelephonyDetailsLoaded] = useState(false);
  const [hasError, setError] = useState(false);
  const [hasInternetDetailError, setHasInternetDetailError] = useState(false);
  const [eligibleBenefits, setEligibleBenefits] = useState<TvBenefit[]>([]); // contains the eligible benefits
  const [isBenefitsLoading, setIsBenefitsLoading] = useState(false);
  const [hasBenefitsError, setHasBenefitsError] = useState(false);
  const [productType, setProductType] = useState<CustomerProductType | null>(null);
  const [secretCircleLocked, setSecretCircleLocked] = useState<boolean | undefined>(false);
  const [canChangeSecretCircle, setCanChangeSecretCircle] = useState(false);
  const [hasDigitalTV, setHasDigitalTV] = useState(false);
  const [hasCableTV, setHasCableTV] = useState(false);
  const [benefitInfo, setBenefitInfo] = useState<BenefitInfo>();

  const getCobrowseSession = () => {
    CoBrowsingApi.isCobrowseAgent()
      .then(({ data }) => setIsCobrowseSession(data.enabled))
      .catch(() => setIsCobrowseSession(false));
  };

  const getDashboardProducts = async () => {
    const { activeBcId } = billingCustomerContext;
    if (!activeBcId) return;
    setLoading(true);
    try {
      const { data } = await AssignedProductApi.getAssignedProductsDashboard(activeBcId);
      setProducts(mergeDeepLeft(products || {}, data));

      const { data: dashboardV2Data } = await DashboardApi.getDashboardInfoV2(activeBcId);
      if (data.benefitInfo) {
        setBenefitInfo(data.benefitInfo);
      }
      setProductType(dashboardV2Data?.productType);
      setHasDigitalTV(!!dashboardV2Data?.productCategories?.dtv || !!data.hasDigitalTV);
      setHasCableTV(!!dashboardV2Data?.productCategories?.catv);
      setDashboardProductsLoaded(true);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getOverviewProducts = async () => {
    const bcId = billingCustomerContext.activeBcId;
    if (!bcId) return;
    setLoading(true);
    try {
      let prod = products;
      const { data } = await AssignedProductApi.getAssignedProductsOverview(bcId);

      const { internetProducts, televisionProducts, telephonyProducts } = data;
      if (!hasInternetDetailsLoaded) {
        prod = mergeDeepRight(prod || {}, { internetProducts }) as Products;
      }
      if (!hasTvDetailsLoaded) {
        prod = mergeDeepRight(prod || {}, { televisionProducts }) as Products;
      }
      if (!hasTelephonyDetailsLoaded) {
        prod = mergeDeepRight(prod || {}, { telephonyProducts }) as Products;
      }

      if (data.benefitInfo) {
        setBenefitInfo(data.benefitInfo);
      }
      setProducts(prod);
      setOverviewLoaded(true);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getDetailedProducts = async (productType: ProductType) => {
    const bcId = billingCustomerContext.activeBcId;
    if (!bcId) return;
    try {
      setLoading(true);
      if (productType === ProductType.TV) {
        setIsTvLoading(true);
        const { data: televisionProducts } = await AssignedProductApi.getFullTVProducts(bcId);
        setProducts((products) => mergeDeepRight(products || {}, { televisionProducts }) as Products);
        setHasTvDetailsLoaded(true);
        setIsTvLoading(false);
      } else if (productType === ProductType.INTERNET) {
        try {
          setIsInternetLoading(true);
          const { data: internetProducts } = await AssignedProductApi.getFullInternetProducts(bcId);
          setProducts((products) => mergeDeepRight(products || {}, { internetProducts }) as Products);
        } catch (e) {
          setHasInternetDetailError(true);
          if (e.data?.getAssignedProducts?.internetProducts) {
            setProducts(
              (products) =>
                mergeDeepRight(products || {}, {
                  internetProducts: e.data.getAssignedProducts.internetProducts,
                }) as Products
            );
          }
        } finally {
          setIsInternetLoading(false);
          setHasInternetDetailsLoaded(true);
        }
      } else if (productType === ProductType.TELEPHONY) {
        setIsTelephonyLoading(true);
        const { data: telephonyProducts } = await AssignedProductApi.getFullTelephonyProducts(bcId);
        setProducts((products) => mergeDeepRight(products || {}, { telephonyProducts }) as Products);
        setHasTelephonyDetailsLoaded(true);
        setIsTelephonyLoading(false);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getCallBars = async (telephonyProductIds: string[]) => {
    if (isCallBarsLoading) return;

    const existingCallBar = get(callBars, telephonyProductIds, '');
    if (existingCallBar.length && existingCallBar !== 'failed') return;

    setIsCallBarsLoading(true);
    const bcId = billingCustomerContext.activeBcId;
    let callBarsData: CallBars = {};
    await Promise.all(
      telephonyProductIds.map(async (telephonyProductId) => {
        try {
          const { data } = await CallBarsApi.getCallBars(bcId, telephonyProductId);
          callBarsData = {
            ...callBarsData,
            [telephonyProductId]: {
              callBars: data.callBars,
              allowChange: data.allowChange,
              reason: data.reason,
            },
          };
        } catch {
          callBarsData = {
            ...callBarsData,
            [telephonyProductId]: {
              callBars: 'failed',
              allowChange: false,
              reason: null,
            },
          };
        }
      })
    );

    setCallBars(callBarsData);
    setIsCallBarsLoading(false);
  };

  const postCallBars = async (telephonyProductId: string, updatedCallBars: string, existingCallBars: string) => {
    const bcId = billingCustomerContext.activeBcId;
    const result = await CallBarsApi.postCallBars(bcId, telephonyProductId, updatedCallBars, existingCallBars);
    if (result.data.success && callBars) {
      const newCallBars = { ...callBars };
      newCallBars[telephonyProductId].callBars = updatedCallBars;
      setCallBars(newCallBars);
    }
    return result.data.success;
  };

  const getEligibleBenefits = async () => {
    if (isBenefitsLoading || (!hasDigitalTV && products && UserUtil.hasOnlyDtvOffering(products))) return;

    const { activeBcId } = billingCustomerContext;
    try {
      setIsBenefitsLoading(true);
      const { data } = await TelevisionApi.getTvBenefits(activeBcId);
      setEligibleBenefits(data);
    } catch {
      setHasBenefitsError(true);
    } finally {
      setIsBenefitsLoading(false);
    }
  };

  const canUpdatePhoneSettings = async (telephonyProductId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    try {
      const response = await ComponentIdentifierApi.canUpdatePhoneSettings(
        bcId,
        ComponentIdentifierParameter.PHONE_SETTINGS,
        telephonyProductId
      );
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const getPhoneSettings = async (telephonyProductId: string): Promise<PhoneSettings> => {
    const bcId = billingCustomerContext.activeBcId;
    const { data } = await PhoneSettingsApi.getPhoneSettings(bcId, telephonyProductId);
    return data;
  };

  const postPhoneSettings = async (telephonyProductId: string, phoneSettings: PhoneSettings) => {
    const bcId = billingCustomerContext.activeBcId;
    await PhoneSettingsApi.postPhoneSettings(bcId, telephonyProductId, phoneSettings);
  };

  const changeNumber = (telephonyProductId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    return TelephonyApi.changePhoneNumber(bcId, telephonyProductId);
  };

  const getWifiRoamingStatus = async () => {
    const bcId = billingCustomerContext.activeBcId;
    const userName = authContext.userName || '';
    const response = await WifiSpotApi.getWifiRoamingStatus(bcId, userName);

    return response.data;
  };

  const canGetWifiSpot = async () => {
    const bcId = billingCustomerContext.activeBcId;
    try {
      const response = await ComponentIdentifierApi.canCreateWifiSpot(bcId);
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const canChangeWifiSpot = async () => {
    const bcId = billingCustomerContext.activeBcId;
    try {
      const response = await ComponentIdentifierApi.canChangeWifiSpot(bcId);
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const changeWifiSpot = async (status: WifiSpotStatus) => {
    const bcId = billingCustomerContext.activeBcId;
    const userName = authContext.userName || '';
    const response = await WifiSpotApi.patchWifiSpot(bcId, userName, status);
    return response.data;
  };

  const getWifiSpot = async () => {
    const bcId = billingCustomerContext.activeBcId;
    const userName = authContext.userName || '';
    const response = await WifiSpotApi.getWifiSpot(bcId, userName);
    return response.data;
  };

  const resetWifiSpotPassword = async () => {
    const bcId = billingCustomerContext.activeBcId;
    const userName = authContext.userName || '';
    const response = await WifiSpotApi.resetWifiSpotPassword(bcId, userName);
    return response.data;
  };

  const getInternetSecurityRedirect = async (internetProductId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    const response = await InternetSecurityApi.internetSecurityLink(bcId, internetProductId);
    return response.data;
  };

  const canChangeNumber = async (assignedProductId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    try {
      const response = await ComponentIdentifierApi.componentIdentifierForProduct(
        bcId,
        ComponentIdentifierParameter.CHANGE_PHONE_NUMBER,
        assignedProductId
      );
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const canUpdateCallBars = async (telephonyProductId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    try {
      const response = await ComponentIdentifierApi.componentIdentifierForProduct(
        bcId,
        ComponentIdentifierParameter.CALL_BARS,
        telephonyProductId
      );
      return response.data.enabled;
    } catch (e) {
      // if it fails, just assume that the user can update the call bars. If they can't, the BE will have it covered!
      return true;
    }
  };

  const canRefreshEntitlements = async (productId: string, receiverId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    try {
      const response = await ComponentIdentifierApi.canRefreshEntitlements(bcId, productId, receiverId);
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const refreshEntitlements = (productId: string, receiverId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    return AssignedProductApi.refreshEntitlements(bcId, productId, receiverId);
  };

  const resetPin = (receiverId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    return AssignedProductApi.resetPincode(bcId, receiverId);
  };

  const canResetPin = async (receiverId: string) => {
    const bcId = billingCustomerContext.activeBcId;
    try {
      const response = await ComponentIdentifierApi.componentIdentifierForProduct(
        bcId,
        ComponentIdentifierParameter.RESET_PINCODE,
        receiverId
      );
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const toggleSecretServiceLockState = (enabled: boolean) => {
    const bcId = billingCustomerContext.activeBcId;
    return SecretCircleApi.updateSecretCircle(bcId, { enabled }).then((result) => {
      if (result.data.success) {
        getSecretCircle();
        return true;
      }

      return false;
    });
  };

  const getSecretCircle = () => {
    const bcId = billingCustomerContext.activeBcId;

    return Promise.all([SecretCircleApi.getSecretCircle(bcId), ComponentIdentifierApi.canChangeSecretCircle(bcId)])
      .then(([secretCircleResponse, componentResponse]) => {
        // when enabled it is not locked hence the invert
        const secretCircleLocked = !secretCircleResponse.data.enabled;
        // can we change
        const canChangeSecretCircle = !componentResponse.data.enabled;

        setSecretCircleLocked(secretCircleLocked);
        setCanChangeSecretCircle(canChangeSecretCircle);
      })
      .catch((e) => {
        // Not having controls for Secret Circle shouldn't be a big deal.
        console.error(e);
      });
  };

  const hasTv = () => (products && products?.televisionProducts?.length > 0) || false;
  const hasInternet = () => (products && products?.internetProducts?.length > 0) || false;
  const hasPhone = () => (products && products?.telephonyProducts?.length > 0) || false;
  const hasProducts = () => hasTv() || hasInternet() || hasPhone();
  const hasGroupProduct = () => Boolean(products && products?.groupLevelProduct);

  const hasAllBenefits =
    (eligibleBenefits.length > 0 &&
      eligibleBenefits.every((benefit) => benefit.eligibility === TvBenefitEligibilityEnum.BUNDLECHANGEREQUIRED)) ||
    false;

  useEffect(() => {
    if (billingCustomerContext.completed) getDashboardProducts();
  }, [billingCustomerContext.completed]);

  return (
    <ProductContext.Provider
      value={{
        isLoading,
        hasError,
        products,
        productType,
        callBars,
        isCallBarsLoading,
        eligibleBenefits,
        isBenefitsLoading,
        hasBenefitsError,
        isTvLoading,
        isInternetLoading,
        isTelephonyLoading,
        isCobrowseSession,
        hasDashboardProductsLoaded,
        hasOverviewLoaded,
        hasTvDetailsLoaded,
        hasInternetDetailsLoaded,
        hasTelephonyDetailsLoaded,
        hasAllBenefits,
        hasInternetDetailError,
        secretCircleLocked,
        canChangeSecretCircle,
        hasCableTV,
        hasDigitalTV,
        hasTv,
        hasInternet,
        hasPhone,
        hasProducts,
        hasGroupProduct,
        getOverviewProducts,
        getDetailedProducts,
        getCallBars,
        postCallBars,
        getEligibleBenefits,
        getCobrowseSession,
        getPhoneSettings,
        postPhoneSettings,
        canUpdatePhoneSettings,
        changeNumber,
        getWifiRoamingStatus,
        canGetWifiSpot,
        canChangeWifiSpot,
        changeWifiSpot,
        getWifiSpot,
        resetWifiSpotPassword,
        getInternetSecurityRedirect,
        canChangeNumber,
        canUpdateCallBars,
        canRefreshEntitlements,
        refreshEntitlements,
        resetPin,
        canResetPin,
        toggleSecretServiceLockState,
        getSecretCircle,
        isConverged: !!benefitInfo?.beneficialBrand,
      }}>
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider, useProductContext };
