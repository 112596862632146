import React from 'react';

export const Construction = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" viewBox="0 0 192 192">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#5E422C"
        d="M133.534 160.776c-19.825 0-35.953-16.029-35.953-35.731s16.128-35.73 35.953-35.73c19.825 0 35.953 16.028 35.953 35.73 0 19.702-16.128 35.731-35.953 35.731"
      />
      <path
        fill="#ABB2B2"
        d="M133.535 86.09c-21.649 0-39.198 17.44-39.198 38.955 0 21.514 17.549 38.955 39.198 38.955 21.647 0 39.196-17.44 39.196-38.955 0-21.514-17.549-38.955-39.196-38.955m0 6.448c18.035 0 32.709 14.583 32.709 32.507s-14.674 32.507-32.71 32.507c-18.035 0-32.71-14.583-32.71-32.507s14.675-32.507 32.71-32.507"
      />
      <path
        fill="#82817F"
        d="M141.5 86.894a39.544 39.544 0 0 0-6.955-.792l-.082-.069c1.706.07 3.943.285 6.95.783l.086.078"
      />
      <path
        fill="#36291D"
        d="M159.829 138.363a61.65 61.65 0 0 0-1.045-12.324c-1.285-6.917-3.715-13.573-7.223-19.785-2.477-4.386-5.497-8.553-9.029-12.466a32.609 32.609 0 0 1 6.96 2.887c7.78 12.176 13.134 29.4 10.337 41.688m-1.909-.845c.03-10.254-2.678-28.009-18.715-44.491 16.037 16.482 18.746 34.237 18.715 44.491M155.017 126.731"
      />
      <path
        fill="#5F6462"
        d="M149.492 96.675a32.609 32.609 0 0 0-6.96-2.887 80.627 80.627 0 0 0-7.987-7.686c2.375.06 4.701.33 6.954.792 2.786 2.531 5.514 5.9 7.993 9.781m-10.288-3.648"
      />
      <path
        fill="#ABB2B2"
        d="M145.82 138.443c-19.558-.727-31.856-4.91-47.692-16.894l12.799-14.806 36.006 30.742-1.112.958"
      />
      <path
        fill="#8A8B8A"
        d="M140.939 142.65a2.41 2.41 0 0 1-3.063.065l-5.118-4.04a2.41 2.41 0 0 0-2.994.01l-3.053 2.438-4.287 3.387a63.03 63.03 0 0 1-4.864-1.597c-7.841-2.935-15.252-7.496-22.026-13.554a3.183 3.183 0 0 1-1.063-2.179c-.054-.84.22-1.656.773-2.295l2.884-3.336c15.836 11.985 28.134 16.167 47.693 16.894l-4.882 4.206"
      />
      <path
        fill="#C9CECA"
        d="M147.939 136.618l-1.006.867-36.006-30.742L126.61 88.6a3.214 3.214 0 0 1 2.167-1.104 3.22 3.22 0 0 1 2.327.715c7.218 5.881 12.977 12.579 17.115 19.907 3.302 5.846 5.588 12.109 6.797 18.613a57.927 57.927 0 0 1 .973 11.733l-5.577-2.253a2.41 2.41 0 0 0-2.473.407"
      />
      <path
        fill="#4F5454"
        d="M92.337 122.403l31.367-36.284a7.032 7.032 0 0 1 4.74-2.417 7.048 7.048 0 0 1 5.09 1.564c7.588 6.183 13.654 13.245 18.027 20.988 3.508 6.212 5.937 12.868 7.223 19.786 1.036 5.576 1.158 10.38.992 13.953l-1.887-.762c.372-11.101-2.249-33.49-25.57-52.493a5.166 5.166 0 0 0-7.162.621L93.79 123.644a5.09 5.09 0 0 0 .462 7.13c8.967 8.02 17.957 12.678 26.228 15.27l-1.692 1.338a67.884 67.884 0 0 1-2.466-.863c-8.332-3.103-16.189-7.923-23.352-14.33a6.96 6.96 0 0 1-2.322-4.764 6.95 6.95 0 0 1 1.69-5.022"
      />
      <path fill="#4F5454" d="M98.128 121.549c-.51-.386-1.01-.753-1.528-1.155l12.874-14.892 1.453 1.241-12.8 14.806" />
      <path
        fill="#4F5454"
        d="M95.244 124.885a3.177 3.177 0 0 0-.773 2.295 3.184 3.184 0 0 0 1.063 2.179c6.774 6.058 14.185 10.619 22.026 13.554a63.03 63.03 0 0 0 4.864 1.597l-1.944 1.535c-8.27-2.593-17.26-7.251-26.228-15.27a5.089 5.089 0 0 1-.461-7.13l2.81-3.25c.516.401 1.017.768 1.527 1.154l-2.884 3.336m59.772 1.846c-1.208-6.504-3.495-12.767-6.797-18.613-4.138-7.328-9.897-14.026-17.115-19.907a3.22 3.22 0 0 0-2.327-.715 3.212 3.212 0 0 0-2.167 1.104l-15.683 18.143-1.454-1.241 15.683-18.143a5.167 5.167 0 0 1 7.163-.621c23.321 19.003 25.942 41.392 25.57 52.493l-1.9-.767a57.956 57.956 0 0 0-.973-11.733"
      />
      <path
        fill="#5E422C"
        d="M103.661 100.539c-2.601 3.008-7.164 3.352-10.192.767l-42.06-35.91a7.152 7.152 0 0 1-.773-10.13c2.601-3.009 7.165-3.352 10.192-.767l42.06 35.91a7.152 7.152 0 0 1 .773 10.13"
      />
      <path
        fill="#423224"
        d="M90.816 99.041l-31.13-26.578c.41-.203.786-.488 1.103-.855l.392-.453 1.279-1.48L95.8 93.233l-2.928 3.387-1.831 2.12a2.484 2.484 0 0 0-.225.302"
      />
      <path
        fill="#707673"
        d="M44.519 62.343L56.753 48.19l11.29 9.64a3.494 3.494 0 0 1 .378 4.949l-7.632 8.83a3.547 3.547 0 0 1-4.98.374l-11.29-9.64"
      />
      <path
        fill="#777776"
        d="M99.968 88.411l-7.096 8.208c12.628 10.56 19.526 14.058 23.726 15.008a9.323 9.323 0 0 0-1.007-13.202l-12.044-10.283a2.55 2.55 0 0 0-3.579.27"
      />
      <path
        fill="#777776"
        d="M91.04 98.738a2.512 2.512 0 0 0 .272 3.557l11.204 9.566c.248.268.513.524.798.767 3.946 3.37 9.893 2.921 13.284-1-4.2-.951-11.098-4.449-23.726-15.009l-1.831 2.12"
      />
      <path fill="#464B4A" d="M104.894 113.721" />
      <path
        fill="#6B6C6B"
        d="M109.463 114.889h-.024a9.431 9.431 0 0 1-6.923-3.028l-11.204-9.566a2.51 2.51 0 0 1-.884-1.886v-.056a2.504 2.504 0 0 1 .613-1.615l1.831-2.119c12.627 10.56 19.525 14.057 23.726 15.008a9.423 9.423 0 0 1-7.135 3.262"
      />
      <path
        fill="#707673"
        d="M40.948 62.675l18.786-21.733-7.978-6.811c-1.987-1.696-4.991-1.47-6.698.505l-18.88 21.84 7.687 6.564c1.967 1.68 5.056 1.512 7.083-.365M65.966 42.5L45.214 66.506a3.236 3.236 0 0 1-.178.188 11.484 11.484 0 0 1-7.295 3.202c-2.816.165-5.523-.732-7.621-2.523l-9.868-8.425a2.845 2.845 0 0 1-.307-4.028l20.752-24.007c3.772-4.363 10.414-4.863 14.805-1.115L65.66 38.47a2.844 2.844 0 0 1 .306 4.028"
      />
      <path fill="#423224" d="M62.46 69.676h-.001l2.167-2.507h.001l-2.167 2.507" />
      <path fill="#2F261D" d="M61.181 71.155l1.278-1.479h.001l-1.279 1.479" />
      <path
        fill="#525654"
        d="M58.12 72.826h-.025a3.545 3.545 0 0 1-2.295-.85l-8.811-7.523 6.138-7.102 11.5 9.818-3.83 4.43a3.543 3.543 0 0 1-2.676 1.227"
      />
      <path
        fill="#525654"
        d="M46.989 64.453l-.555-.474a.97.97 0 0 1-.341-.719v-.042a.967.967 0 0 1 .237-.614l5.499-6.362 1.298 1.11-6.138 7.1"
      />
      <path
        fill="#36291D"
        d="M118.779 147.389c-.175-.06-.347-.122-.495-.175l.504.168-.01.007m.83-.655l.872-.69.905-.714-1.777 1.404M159.8 140.002l-1.014-.41.991.4v-.004l.023.014m-1.91-.772l.012-.422-.012.423m-1.9-.768c.006-.277.01-.56.01-.85H156c-.001.29-.005.573-.01.85M146.788 137.609l.144-.123 1.006-.867-1.15.99m-14.123-.374"
      />
      <path
        fill="#5F6462"
        d="M145.82 138.443c-.52-.02-1.036-.041-1.546-.066l1.718-1.696.94.804-.143.123-.969.835m-13.154-1.209a79.319 79.319 0 0 1-2.536-.448 3.316 3.316 0 0 1 2.536.448"
      />
      <path
        fill="#4E4E4D"
        d="M122.423 144.51a63.89 63.89 0 0 1-1.273-.377l7.521-6.604a3.308 3.308 0 0 1 1.459-.743c.834.162 1.679.31 2.536.448.086.055.17.115.25.179l4.68 3.533a3.32 3.32 0 0 0 2.054.709c.67 0 1.343-.202 1.919-.61l2.705-2.668c.51.025 1.026.047 1.547.066l-4.883 4.206a2.403 2.403 0 0 1-3.063.066l-5.117-4.04a2.397 2.397 0 0 0-1.492-.516c-.532 0-1.064.175-1.503.525l-3.053 2.439-4.287 3.387"
      />
      <path
        fill="#6D6F6D"
        d="M155.99 138.463l-5.577-2.252a2.41 2.41 0 0 0-2.474.407l-1.006.867-.94-.804 1.82-1.796a3.325 3.325 0 0 1 3.69-.1l4.497 2.828c-.002.29-.005.573-.01.85"
      />
      <path
        fill="#303432"
        d="M118.788 147.382a108.95 108.95 0 0 1-.504-.168l-.452-.165v-.001l1.548-1.36c.368.123.735.242 1.1.357l-.872.689-.82.648m40.988-7.39l-.991-.4-.896-.362.012-.421 1.875 1.179v.005"
      />
      <path
        fill="#303432"
        d="M120.48 146.045a63.97 63.97 0 0 1-1.1-.356l1.77-1.556c.424.13.848.256 1.273.377l-1.943 1.535m37.409-6.815l-1.9-.767c.006-.277.01-.56.011-.85l1.901 1.195-.012.423"
      />
    </g>
  </svg>
);
