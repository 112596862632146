import { Form, Input, RequiredNotice } from 'components/Form';
import { InlineAlert } from 'components/InlineAlert';
import { PasswordChecklist } from 'components/PasswordChecklist';
import { SectionHeader } from 'components/SectionHeader';
import { useEmailContext } from 'context/email/email.context';
import { useProductContext } from 'context/product/product.context';
import * as S from 'pages/Products/pages/InternetProducts/pages/EmailSettings/email-settings.styles';
import React, { useEffect, useState } from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './section-password.content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionPassword' });

interface ISectionPasswordProps {
  emailAddress: string;
}

enum PasswordChangeState {
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILED,
  PASSWORD_CHANGE_NONE,
}

export const SectionPassword = ({ emailAddress }: ISectionPasswordProps) => {
  const { isCobrowseSession, getCobrowseSession } = useProductContext();
  const [passwordChangeState, setPasswordChangeState] = useState<PasswordChangeState>(
    PasswordChangeState.PASSWORD_CHANGE_NONE
  );
  const { changePasswordEmailAddress } = useEmailContext();

  const onPasswordChangeSubmit = (data: any) => {
    return changePasswordEmailAddress(emailAddress, data.password)
      .then((response) => {
        const result = !!response?.data?.success;
        setPasswordChangeState(
          result ? PasswordChangeState.PASSWORD_CHANGE_SUCCESS : PasswordChangeState.PASSWORD_CHANGE_FAILED
        );
      })
      .catch(() => {
        setPasswordChangeState(PasswordChangeState.PASSWORD_CHANGE_FAILED);
      });
  };

  useEffect(() => {
    // check if is CoBrowse Session
    getCobrowseSession();
  }, []);

  return (
    <>
      <SectionHeader>{contentUtil.translate('changePassword')}</SectionHeader>
      {passwordChangeState === PasswordChangeState.PASSWORD_CHANGE_SUCCESS && (
        <InlineAlert
          type="success"
          onClose={() => setPasswordChangeState(PasswordChangeState.PASSWORD_CHANGE_NONE)}
          testid="password-change-success">
          {contentUtil.translateToString('successTitle')}
        </InlineAlert>
      )}
      {passwordChangeState === PasswordChangeState.PASSWORD_CHANGE_FAILED && (
        <InlineAlert
          type="error"
          onClose={() => setPasswordChangeState(PasswordChangeState.PASSWORD_CHANGE_NONE)}
          testid="password-change-failed">
          {contentUtil.translateToString('errorText')}
        </InlineAlert>
      )}

      {!isCobrowseSession && (
        <S.Form>
          <Form
            id="change-password-email-address-form"
            hideCancelButton
            testid="change-password-email-address-form"
            onSubmit={onPasswordChangeSubmit}
            buttonText={contentUtil.translateToString('update')}
            render={(formApi, validators, validateItem, comparePasswords) => (
              <>
                <p className="title">{contentUtil.translate('passwordSectionTitle')}</p>
                <RequiredNotice />
                <Input
                  name="password"
                  label={contentUtil.translate('password')}
                  autoComplete="new-password"
                  id="password"
                  type="password"
                  testid="password"
                  validate={[validators.required, validators.password]}
                  isRequired
                  error={validateItem('password')}
                  hideErrorLabel
                />
                <PasswordChecklist password={formApi.values.password || ''} />
                <Input
                  name="passwordRepeat"
                  label={contentUtil.translate('passwordRepeat')}
                  id="passwordRepeat"
                  autoComplete="off"
                  type="password"
                  testid="passwordRepeat"
                  validate={[validators.required]}
                  isRequired
                  error={comparePasswords('password', 'passwordRepeat', formApi)}
                />
              </>
            )}
          />
        </S.Form>
      )}

      {isCobrowseSession && <InlineAlert type="warning">{contentUtil.translate('isCobrowseSession')}</InlineAlert>}
    </>
  );
};

export default SectionPassword;
