import styled, { css, gapSize, hoverFocus, rem, screenMobilePlusAndSmaller, tablet } from 'theme';
import borderRadius from 'theme/global/border-radius';
import boxShadow from 'theme/global/box-shadow';

export const Card = styled('div')`
  width: 100%;
  max-width: ${rem(500)};
  border-radius: ${borderRadius.base};
  box-shadow: ${boxShadow.section};
  background-color: ${(p) => p.theme.colors.white};
  margin: 0 auto;
  transform: translateY(${() => gapSize(-4)});
`;

export const PaymentOption = styled('button')`
  background-color: ${(p) => p.theme.colors.white};
  width: 100%;
  height: ${rem(150)};
  border: ${rem(0.5)} solid ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  padding: 0 ${() => gapSize(6)} 0 ${() => gapSize(3)};
  justify-content: space-between;
  transition: 300ms ease-in-out;

  &:first-of-type {
    border-top-left-radius: ${borderRadius.base};
    border-top-right-radius: ${borderRadius.base};
    border-bottom: ${rem(1)} solid ${(p) => p.theme.colors.grayL30};
  }

  &:last-of-type {
    border-bottom-left-radius: ${borderRadius.base};
    border-bottom-right-radius: ${borderRadius.base};
  }

  ${(p) =>
    hoverFocus(css`
      cursor: pointer;
      background-color: ${p.theme.colors.gray100};
      border-color: #bcc1c5;
    `)}

  ${() =>
    screenMobilePlusAndSmaller(css`
      padding: 0 ${gapSize(2)};
    `)}
`;

export const PaymentOptionText = styled('div')`
  width: 100%;
  text-align: left;
`;

export const ChevronWrapper = styled('div')`
  transform: rotate(0.75turn);
  svg {
    width: ${rem(15)};
    height: ${rem(15)};
  }
`;

export const IconWrapper = styled('div')`
  min-width: ${rem(160)};
  text-align: center;

  ${screenMobilePlusAndSmaller(css`
    min-width: ${rem(120)};
  `)}
`;

const Iframe = styled('iframe')`
  border: none;
  height: 100%;
  width: ${rem(320)};
  max-width: 100%;
  margin: 0 auto;
`;

export const IngenicoIframe = styled(Iframe)`
  height: ${rem(650)};

  ${tablet(css`
    margin: 0 auto;
    width: 100%;
  `)}
`;

export const IdealIframe = styled(Iframe)`
  height: ${rem(122)};

  ${tablet(css`
    margin: 0 0 0 auto;
    width: ${rem(262)};
  `)}
`;

export const CreditcardIframe = styled(Iframe)`
  height: ${rem(450)};
  width: ${rem(400)};
`;

export const IframeWrapper = styled('div')`
  padding: ${() => gapSize(6)};
  display: flex;
  align-items: top;

  svg {
    width: ${rem(84)};
    height: ${rem(72)};
  }

  ${() =>
    screenMobilePlusAndSmaller(css`
      padding: ${gapSize(1)};
      flex-direction: column;
      align-items: center;

      svg {
        margin-bottom: ${gapSize(2)};
      }
    `)}
`;

export const ErrorContainer = styled('div')`
  padding: ${() => gapSize(2)};
`;
