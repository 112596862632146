import styled, { css, desktop, gapSize, tablet } from 'theme';

// calculates width based on provided value
export const colWidth = (cols: number, marginEm: string | number, totalCols: number) => css`
  width: calc(${(100 / totalCols) * cols}% - ${marginEm});
`;

interface ColProps {
  s?: number;
  m?: number;
  l?: number;
  flexDirection?: string;
  isHidden?: boolean;
}

// const containerWidth = 1192;
const spacingMobile = 2; // gutter = 8 so 16
const spacingDesktop = 3; // gutter = 8 so 32

export const Col = styled.div.attrs<ColProps>((props) => ({
  ...props,
  flexDirection: props.flexDirection || 'column',
  s: props.s || 4, // screenTabletPortraitAndSmaller
  m: props.m || props.s, // tabletLandscape
  l: props.l || props.m || props.s || 12, // desktop
}))<ColProps>`
  display: ${(props: ColProps) => (props.isHidden ? 'none' : 'flex')};
  flex-direction: ${(p) => p.flexDirection};
  width: 100%;
  margin: ${() => gapSize(spacingMobile / 2)};
  ${(p) => colWidth(p.s!, gapSize(spacingMobile), 4)}
  ${(p) => p.m && tablet(colWidth(p.m, gapSize(spacingDesktop), 8))};
  /* because our gapSize changes for "Desktop" we should make sure we pick any provided value as previous queries will not work correctly */
  ${(p) => p.l && desktop(colWidth(p.l || p.m || p.s!, gapSize(3), 12))}
  ${() =>
    tablet(css`
      margin: ${gapSize(spacingDesktop / 2)};
    `)}
`;

export const Row = styled.div<{ noMargin?: boolean; noPadding?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: ${(p) => !p.noMargin && gapSize(spacingMobile / 2)} 0;
  padding: 0 ${(p) => (p.noPadding ? gapSize(spacingMobile / 2) : '0')};

  ${(p) =>
    tablet(css`
      flex-direction: row;
      /* Disabled since stylelint attempts to parse !p.noMargin as css */
      margin: ${!p.noMargin && gapSize(spacingDesktop / 2)} 0; /* stylelint-disable-line */
      padding: 0 ${!p.noPadding ? gapSize(spacingDesktop / 2) : '0'};
    `)}
`;
