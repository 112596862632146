import { AuthContext } from 'context/auth/auth.context';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { history } from 'utils';
import { BrowserEnum, deviceUtils } from 'utils/device';

import * as S from './base-layout.styles';
import { Footer } from './components/Footer';
import { Header } from './components/Header';

interface IBaseLayoutProps {
  children: React.ReactNode;
}

export const BaseLayoutContext = React.createContext({
  setShowHeaderAndFooter: (_show: boolean): void => {
    return undefined;
  },
  setShowHeader: (_show: boolean): void => {
    return undefined;
  },
  showHeaderAndFooter: true,
  showHeader: true,
});

const BaseLayoutContextProvider = (props: IBaseLayoutProps) => {
  const { children } = props;
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    // reset the show header and footer on route change
    history.listen(() => {
      setShowHeaderAndFooter(true);
      setShowHeader(true);
    });
  }, []);

  return (
    <BaseLayoutContext.Provider
      value={{
        setShowHeaderAndFooter,
        setShowHeader,
        showHeader,
        showHeaderAndFooter,
      }}>
      <BaseLayout>{children}</BaseLayout>
    </BaseLayoutContext.Provider>
  );
};

export const BaseLayout = (props: IBaseLayoutProps) => {
  const { children } = props;
  const baseLayoutContext = useContext(BaseLayoutContext);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (window.dataLayer) return;

    const tagManagerArgs = {
      // eslint-disable-next-line no-underscore-dangle
      gtmId: window?._ddi?.page?.gtm_id || 'GTM_000000',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <S.Base>
      {/* The direct use of the AuthContainer here is disgusting, find a proper use for it when we move away from unstated */}
      {!deviceUtils.isBrowser(BrowserEnum.app) &&
        baseLayoutContext.showHeaderAndFooter &&
        baseLayoutContext.showHeader && <Header isAuthenticated={isAuthenticated} />}
      <S.Main id="main-content">{children}</S.Main>
      {!deviceUtils.isBrowser(BrowserEnum.app) && baseLayoutContext.showHeaderAndFooter && <Footer />}
    </S.Base>
  );
};
export default BaseLayoutContextProvider;
