import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { Button } from 'components/Button';
import { PiiMask } from 'components/PiiMask';
import { SectionHeader } from 'components/SectionHeader';
import { AccessStateContext } from 'context/access-state.context';
import { useEmailContext } from 'context/email/email.context';
import * as S from 'pages/Products/pages/InternetProducts/components/EmailCard/email-card.styles';
import React, { useContext, useState } from 'react';
import { history } from 'utils';
import { ContentUtil } from 'utils/content';

import { ConfirmRemovalModal } from './components/ConfirmRemovalModal';
import { RemovalFailedModal } from './components/RemovalFailedModal';
import { RemovalSuccessModal } from './components/RemovalSuccessModal';
import { content } from './section-email.content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionEmail' });

interface ISectionEmailProps {
  emailAddress: string;
  emailAliases: string[];
}

export const SectionEmail = ({ emailAddress, emailAliases }: ISectionEmailProps) => {
  const { deleteEmailAddress } = useEmailContext();
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const emailAliasList = emailAliases.join(', ');
  const { currentUserCeased } = useContext(AccessStateContext);

  const deleteEmailAddressConfirmed = async () => {
    try {
      await deleteEmailAddress(emailAddress);
      setShowConfirmationModal(false);
      setIsSuccess(true);
    } catch (e) {
      setIsFailed(true);
    }
  };

  return (
    <>
      <SectionHeader>{contentUtil.translateToString('emailHeader')}</SectionHeader>
      <PiiMask>
        <S.EmailAddressAliasesList>
          <S.EmailAddressMainListItem data-testid="email-address">
            {emailAddress}
            <Button
              onClick={() => setShowConfirmationModal(true)}
              buttonVariant={ButtonVariant.LINK}
              testid="button-delete-email-address">
              {contentUtil.translate('deleteEmailAddress')}
            </Button>
          </S.EmailAddressMainListItem>
          {emailAliases &&
            emailAliases.map((alias) => (
              <S.EmailAddressAliasListItem key={alias} data-testid={`email-alias-${alias}`}>
                {alias}
              </S.EmailAddressAliasListItem>
            ))}
        </S.EmailAddressAliasesList>
      </PiiMask>
      {showConfirmationModal && (
        <ConfirmRemovalModal
          onConfirm={() => deleteEmailAddressConfirmed()}
          onCancel={() => setShowConfirmationModal(false)}
          closeModal={isFailed || isSuccess}
          emailAddress={emailAddress}
          emailAliasList={emailAliasList}
        />
      )}
      {isFailed && <RemovalFailedModal closeModal={() => setIsFailed(false)} />}
      {isSuccess && (
        <RemovalSuccessModal
          emailAddress={emailAddress}
          isCeasedCustomer={!!currentUserCeased}
          onClose={() =>
            currentUserCeased
              ? history.push('/mijn-ziggo/overzicht')
              : history.push('/mijn-ziggo/producten/internet#email')
          }
        />
      )}
    </>
  );
};

export default SectionEmail;
