export enum OrderStatus {
  OPEN = 'OPEN',
  OPEN_A = 'OPEN_A',
  OPEN_B = 'OPEN_B',
  OPEN_C = 'OPEN_C',
  SUBMITTED = 'SUBMITTED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  SHIPPED = 'SHIPPED',
}

export enum NumberPortingPortingStatusEnum {
  INITIAL = 'INITIAL',
  APPROVED = 'APPROVED',
  NOTAPPROVED = 'NOT_APPROVED',
  CANCELLED = 'CANCELLED',
  PERFORMED = 'PERFORMED',
}

export enum ProviderTransferTransferStatusEnum {
  INITIAL = 'INITIAL',
  APPROVED = 'APPROVED',
  NOTAPPROVED = 'NOT_APPROVED',
  TERMINATED = 'TERMINATED',
  PERFORMED = 'PERFORMED',
}

export declare enum OrderLineActionEnum {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  UPDATE = 'UPDATE',
  SUSPEND = 'SUSPEND',
  RESUME = 'RESUME',
}

export interface OrderAddress {
  id: string;
  postCode: string;
  city: string;
  country: string;
  streetName: string;
  houseNumber: number;
  houseNumberExtension?: string;
  roomNumber?: string;
}

export interface OrderCost {
  includingVat: number;
  excludingVat: number;
}

export interface OrderLine {
  productName: string;
  category: string;
  action?: OrderLineActionEnum;
  recurringCost: OrderCost;
  onetimeCost: OrderCost;
}

export interface OrderDelivery {
  deliveryMethod: string;
  trackAndTrace?: string;
  address?: OrderAddress;
}

export interface OrderContact {
  id: string;
  birthDate?: string;
  salutation: string;
  initials: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  phone: string;
}

export interface OrderMove {
  moveAddress?: OrderAddress;
  moveDate?: string;
  phoneNumberChanges?: number[];
}

export interface NumberPorting {
  phoneNumber: number;
  portingStatus: NumberPortingPortingStatusEnum;
}

export interface ProviderTransfer {
  provider: string;
  transferStatus: ProviderTransferTransferStatusEnum;
}

export interface Order {
  id: string;
  orderStatus: OrderStatus;
  creationDate: string;
  startDate: string;
  consolidatedStatus?: string;
  dueDate?: string;
  orderDelivery?: OrderDelivery;
  address: OrderAddress;
  orderLines?: OrderLine[];
  contact?: OrderContact;
  totalOnetime?: OrderCost;
  totalRecurring?: OrderCost;
  orderMove?: OrderMove;
  numberPorting?: NumberPorting[];
  providerTransfer?: ProviderTransfer;
  pointOfNoReturnPassed?: boolean;
  acquisitionOrder?: boolean;
  validDateList?: string[];
}

export interface OrderValidDateList {
  validDateList: string[];
}
