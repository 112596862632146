import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    passwordSectionTitle: 'Bedenk een nieuw wachtwoord voor dit e-mailadres.',
    password: 'Wachtwoord',
    passwordRepeat: 'Herhaal je wachtwoord',
    update: 'Nu wijzigen',
    changePassword: 'Een nieuw wachtwoord',
    errorText: 'Je nieuwe wachtwoord opslaan lukt niet. Probeer je het nog eens?',
    successTitle:
      'Je wachtwoord is gewijzigd. Pas het wachtwoord aan op alle apparaten waarop je dit e-mailadres gebruikt.',
    isCobrowseSession: 'Via cobrowsen kun je geen nieuw wachtwoord voor de klant maken.',
  },
  en: {},
};
