import { LogoZiggo } from 'assets/img/Logo';
import Image from 'assets/img/ziggo-error.gif';
import { BaseLayoutContext } from 'components/BaseLayout/BaseLayout';
import React, { useContext } from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './error.content';
import * as S from './error.styles';

const contentUtil = new ContentUtil({ content, componentName: 'Error' });

export const Error = () => {
  const baseLayoutContext = useContext(BaseLayoutContext);
  baseLayoutContext.setShowHeaderAndFooter(false);

  return (
    <S.Error>
      <S.ErrorHeader>
        <LogoZiggo />
      </S.ErrorHeader>
      <S.ErrorContent>
        <S.Img>
          <img src={Image} />
        </S.Img>
        <S.Message>
          <h1>{contentUtil.translate('header')}</h1>
          <h3>{contentUtil.translate('text')}</h3>
          <S.Button as="a" onClick={() => window.location.reload()}>
            {contentUtil.translate('refresh')}
          </S.Button>
        </S.Message>
      </S.ErrorContent>
      <S.ErrorFooter>
        <S.Support>
          <b>{contentUtil.translate('supportHeader')}</b>
          <S.SupportLinks>
            <a href={contentUtil.translateToString('supportLinks.klantenservice.url')}>
              {contentUtil.translate('supportLinks.klantenservice.text')}
            </a>
            <a href={contentUtil.translateToString('supportLinks.storingschecker.url')}>
              {contentUtil.translate('supportLinks.storingschecker.text')}
            </a>
            <a href={contentUtil.translateToString('supportLinks.community.url')}>
              {contentUtil.translate('supportLinks.community.text')}
            </a>
          </S.SupportLinks>
        </S.Support>
      </S.ErrorFooter>
    </S.Error>
  );
};
