import React, { SVGProps } from 'react';

export const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0017 1L0.00170898 17C1.56371 18.562 4.09571 18.562 5.65771 17L6.00171 16.658V31H12.0017V23C12.0017 21.896 12.8957 21 14.0017 21H18.0017C19.1077 21 20.0017 21.896 20.0017 23V31H26.0017V16.658L26.3457 17C27.9077 18.562 30.4397 18.562 32.0017 17L16.0017 1Z"
      fill="#1C1D1E"
    />
  </svg>
);
