import React, { SVGProps } from 'react';

export const DeliveryArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.02905 14.499C8.83205 14.91 8.33905 15.083 7.92705 14.886C7.67205 14.764 7.49605 14.518 7.46505 14.235L6.97205 9.90601C6.93005 9.52901 6.63405 9.22901 6.25805 9.18101L1.72105 8.60201C1.26805 8.54401 0.949051 8.13001 1.00705 7.67801C1.04305 7.40001 1.21705 7.15901 1.46805 7.03801L13.8171 1.11401C14.2281 0.917008 14.7211 1.09001 14.9191 1.50101C15.0271 1.72701 15.0271 1.98901 14.9191 2.21501L9.02905 14.499Z"
      fill="#498514"
    />
  </svg>
);
