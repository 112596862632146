import { SearchIcon } from 'assets/icons';
import { appSettings } from 'config/app.settings';
import { useClickOutsideAndKeyNavigation } from 'hooks/use-click-outside-and-key-navigation.hook';
import React, { FormEvent, RefObject, useEffect, useRef, useState } from 'react';

import * as S from './search.styles';

interface ISearchProps {
  toggleSearchBar: () => void;
}

export const Search = ({ toggleSearchBar }: ISearchProps) => {
  const [value, setValue] = useState('');
  const inputEl = useRef<HTMLInputElement>(null);

  const ref = useClickOutsideAndKeyNavigation((_event: MouseEvent | TouchEvent | KeyboardEvent) => {
    toggleSearchBar();
  });

  useEffect(() => {
    inputEl?.current?.focus();
  }, [inputEl]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value) {
      window.location.href = `${appSettings.ENV_ZIGGO_URL}/zoeken/?q=${encodeURI(value)}`;
    }
  };

  return (
    <S.Form onSubmit={onSubmit} ref={ref as RefObject<HTMLFormElement>}>
      <S.SearchContainer>
        <S.SearchWrapper>
          <S.Search ref={inputEl} data-testid="search-input" onChange={(e) => setValue(e.target.value)} />
          <S.Button type="submit">
            <SearchIcon />
          </S.Button>
        </S.SearchWrapper>
      </S.SearchContainer>
    </S.Form>
  );
};
