import React, { SVGProps } from 'react';

export const SlidersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="24" viewBox="0 0 25 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66699 6H7.08249C7.60299 6.8925 8.55999 7.5 9.66699 7.5C10.774 7.5 11.731 6.8925 12.2515 6H20.167C21.823 6 23.167 4.656 23.167 3H12.2515C11.731 2.106 10.774 1.5 9.66699 1.5C8.55999 1.5 7.60299 2.106 7.08249 3H3.66699C2.83749 3 2.16699 3.6705 2.16699 4.5C2.16699 5.328 2.83749 6 3.66699 6ZM17.167 9C16.06 9 15.103 9.606 14.5825 10.5H3.66699C2.83749 10.5 2.16699 11.1705 2.16699 12C2.16699 12.828 2.83749 13.5 3.66699 13.5H14.5825C15.103 14.3925 16.06 15 17.167 15C18.274 15 19.231 14.3925 19.7515 13.5H20.167C21.823 13.5 23.167 12.156 23.167 10.5H19.7515C19.231 9.606 18.274 9 17.167 9ZM8.58249 18C9.10299 17.106 10.06 16.5 11.167 16.5C12.274 16.5 13.231 17.106 13.7515 18H23.167C23.167 19.656 21.823 21 20.167 21H13.7515C13.231 21.8925 12.274 22.5 11.167 22.5C10.06 22.5 9.10299 21.8925 8.58249 21H3.66699C2.83749 21 2.16699 20.328 2.16699 19.5C2.16699 18.6705 2.83749 18 3.66699 18H8.58249Z"
      fill="#1C1D1E"
    />
  </svg>
);
