import React from 'react';

export const DevicesInternet = () => (
  <svg width="192" height="192" viewBox="0 0 192 192">
    <defs>
      <path id="devices-internet-a" d="M.011 25.02V.019h25.115l-7.047 7.015H1.708v16.298z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#C7D0D5" d="M145.198 108.923H26.06v-54.56c0-10.883-.76-19.922 16.707-19.922l102.43.216v74.266" />
      <path
        fill="#5D6363"
        d="M27.174 107.814h116.91V35.766H37.221c-5.54 0-10.047 4.487-10.047 10.002v62.046m119.137 2.217H24.947V45.768c0-6.738 5.506-12.22 12.274-12.22h109.09v76.483"
      />
      <path fill="#D2D6C4" d="M139.63 103.38H31.628V53.25c0-7.207 5.87-13.05 13.11-13.05h94.894v63.18" />
      <path
        fill="#EAEBE6"
        d="M38.308 43.525a3.333 3.333 0 0 0-3.34 3.325v25.495l28.95-28.82h-25.61M138.517 72.345l-30.062 29.927h30.062V72.345"
      />
      <path fill="#FFFFFE" d="M138.517 102.272H34.967V50.051c0-3.604 2.935-6.526 6.556-6.526h96.994v58.747" />
      <path
        fill="#D2D6C4"
        d="M148.539 116.682H22.719c-4.304 0-7.793-3.474-7.793-7.76h141.407c0 4.286-3.49 7.76-7.794 7.76"
      />
      <path
        fill="#FFFFFE"
        d="M153.034 113.357H20.451a2.18 2.18 0 0 1-2.185-2.176v-1.15h136.953v1.15a2.18 2.18 0 0 1-2.185 2.176"
      />
      <path
        fill="#5D6363"
        d="M16.04 110.031v2.217a3.337 3.337 0 0 0 3.34 3.326h132.499a3.337 3.337 0 0 0 3.34-3.326v-2.217H16.04m135.84 7.76h-132.5c-3.069 0-5.567-2.486-5.567-5.543v-4.434h143.634v4.434c0 3.057-2.498 5.543-5.567 5.543"
      />
      <path
        fill="#EC904A"
        d="M68.763 78.142c-.284 0-.569-.108-.786-.325a1.104 1.104 0 0 1 0-1.567l20.278-20.188a1.117 1.117 0 0 1 1.575 0 1.105 1.105 0 0 1 0 1.567L69.55 77.817a1.112 1.112 0 0 1-.787.325m-.557 12.232c-.29 0-.58-.113-.8-.337a1.104 1.104 0 0 1 .026-1.567l35.678-34.418a1.117 1.117 0 0 1 1.574.025c.428.44.416 1.14-.025 1.567L68.98 90.062a1.112 1.112 0 0 1-.775.312"
      />
      <path
        fill="#EAEBE6"
        d="M38.308 43.525a3.333 3.333 0 0 0-3.34 3.325v32.73l36.217-36.055H38.308M138.517 73.441l-28.961 28.831h28.961V73.441"
      />
      <path
        fill="#5D6363"
        d="M32.74 102.273h105.778V41.308H38.308c-3.07 0-5.567 2.486-5.567 5.542v55.423m108.004 2.216H30.514V46.85c0-4.278 3.496-7.758 7.794-7.758h102.437v65.397"
      />
      <path
        fill="#AFB3B0"
        d="M73.575 120.97H16.984a2.603 2.603 0 0 1-2.602-2.59 2.59 2.59 0 0 1 1.14-2.139 5.56 5.56 0 0 0 3.857 1.55h54.196v3.18"
        opacity=".5"
      />
      <path fill="#5C6362" d="M73.575 117.791H19.379a5.56 5.56 0 0 1-3.857-1.55c.418-.284.921-.45 1.462-.45h56.59v2" />
      <path
        fill="#AFB3B0"
        d="M128.887 141.196H72.854v-3.947h.72v2.259h.992v-.987h.99-.99v.987h54.321v1.688zm-53.33-3.662h53.33v-.06h-53.33v.06z"
        opacity=".5"
      />
      <path fill="#EAEBE6" d="M82.18 99.954l16.123-16.051H82.18v16.051" />
      <path
        fill="#F6F6F8"
        d="M149.6 83.256v42.724c0 3.874-3.155 7.014-7.046 7.014H82.092V87.77c0-2.492 2.03-4.513 4.533-4.513H149.6"
      />
      <path
        fill="#FFFFFE"
        d="M156.974 87.897v38.736c0 4.103-3.34 7.43-7.463 7.43H83.523V95.326c0-4.104 3.342-7.43 7.463-7.43h65.988"
      />
      <path
        fill="#C7D0D5"
        d="M158.474 76.905v53.14c0 4.103-3.342 7.43-7.464 7.43H74.566v-53.14c0-4.104 3.341-7.43 7.463-7.43h76.445"
      />
      <path
        fill="#FFFFFE"
        d="M149.6 83.256v42.724c0 3.874-3.155 7.014-7.046 7.014H82.092V87.77c0-2.492 2.03-4.513 4.533-4.513H149.6"
      />
      <path
        fill="#EAEBE6"
        d="M117.114 132.056l31.799-31.656v31.656h-31.799M82.628 115.113l31.799-31.656H82.628v31.656"
      />
      <path
        fill="#616565"
        d="M149.6 84.243l-.992.008v41.729a5.99 5.99 0 0 1-1.773 4.262 6.043 6.043 0 0 1-4.28 1.766H83.082V87.769c0-.977.395-1.852 1.038-2.493a3.534 3.534 0 0 1 2.504-1.033H149.6v-.987h-.991.991v-.986H86.625c-3.051 0-5.523 2.462-5.524 5.5v46.21h61.453c4.439 0 8.036-3.581 8.036-8V82.27h-.99V84.243z"
      />
      <path
        fill="#5D6363"
        d="M75.557 138.521V84.335c0-1.782.723-3.387 1.896-4.556a6.46 6.46 0 0 1 4.576-1.887h75.453v53.2a6.404 6.404 0 0 1-1.895 4.556 6.462 6.462 0 0 1-4.577 1.886H75.534v.987h-.968.99-.99v.987h76.444c4.67 0 8.454-3.768 8.454-8.417V75.92H82.03c-4.67 0-8.453 3.767-8.454 8.416v55.173h.99v-.987h.992z"
      />
      <path
        fill="#5D6363"
        d="M153.615 103.966c.866 0 1.568.7 1.568 1.562 0 .861-.702 1.56-1.568 1.56a1.564 1.564 0 0 1-1.567-1.56c0-.863.702-1.562 1.567-1.562"
      />
      <path
        fill="#EC904A"
        d="M122.067 100.224c.224 0 .449.085.62.256a.87.87 0 0 1 0 1.236l-15.991 15.92a.88.88 0 0 1-1.242 0 .87.87 0 0 1 0-1.236l15.992-15.92a.876.876 0 0 1 .62-.256m.44-9.646a.88.88 0 0 1 .63.265c.338.347.33.9-.019 1.237l-28.134 27.14a.881.881 0 0 1-1.242-.02.871.871 0 0 1 .02-1.235l28.134-27.14a.878.878 0 0 1 .611-.247"
      />
      <path
        fill="#FFFFFE"
        d="M167.5 155.91H137.34c-4.122 0-7.463-3.327-7.463-7.43V91.706h30.158c4.122 0 7.463 3.326 7.463 7.43v56.773"
      />
      <path
        fill="#C7D0D5"
        d="M167.5 155.91H137.34c-4.122 0-7.463-3.327-7.463-7.43V91.706h30.158c4.122 0 7.463 3.326 7.463 7.43v56.773"
      />
      <path
        fill="#5D6363"
        d="M151.376 150.336a2.68 2.68 0 0 1-2.687 2.675 2.68 2.68 0 0 1-2.687-2.675 2.68 2.68 0 0 1 2.687-2.675 2.68 2.68 0 0 1 2.687 2.675"
      />
      <path fill="#FFFFFE" d="M132.566 145.432h32.246V99.954h-32.246z" />
      <path fill="#FFFFFE" d="M132.565 100.756h32.247v-1.048h-32.247z" />
      <g transform="translate(130.858 92.674)">
        <mask id="devices-internet-b" fill="#fff">
          <use href="#devices-internet-a" />
        </mask>
        <path fill="#AAB5AF" mask="url(#devices-internet-b)" d="M.011 25.02h25.115V.019H.011z" />
      </g>
      <path fill="#D2D5C4" d="M132.565 100.756v-1.048h16.372l-.248.524h-16.124v.524" />
      <path fill="#EAEBE6" d="M132.565 116.005l16.124-16.051h-16.124v16.051" />
      <path
        fill="#5D6363"
        d="M167.5 155.91v-.987H137.34c-1.79 0-3.402-.72-4.576-1.887a6.404 6.404 0 0 1-1.896-4.556V92.692h29.167c1.79 0 3.403.72 4.577 1.888a6.402 6.402 0 0 1 1.895 4.555v56.774h.991v-.986.986h.991V99.135c0-4.649-3.784-8.415-8.454-8.415h-31.149v57.76c.001 4.648 3.784 8.416 8.454 8.416h31.15v-.987h-.992"
      />
      <path
        fill="#5D6363"
        d="M141.971 97.374h13.436a.989.989 0 0 0 .99-.987.989.989 0 0 0-.99-.986H141.97a.989.989 0 0 0-.991.986c0 .545.443.987.991.987"
      />
      <path
        fill="#EC904A"
        d="M156.947 115.483c.158 0 .315.06.435.18a.61.61 0 0 1 0 .865l-11.199 11.15c-.24.239-.63.239-.87 0a.61.61 0 0 1 0-.866l11.2-11.15c.12-.119.278-.179.434-.179m.308-6.755c.161 0 .32.062.442.187a.61.61 0 0 1-.014.865l-19.703 19.007a.616.616 0 0 1-.87-.013.61.61 0 0 1 .014-.866l19.703-19.007a.612.612 0 0 1 .428-.173"
      />
      <path
        fill="#AFB3B0"
        d="M170.473 157.968h-38.156a1.483 1.483 0 0 1-1.486-1.48c0-.722.52-1.323 1.207-1.454a8.444 8.444 0 0 0 5.303 1.862h31.15v-1.888h1.982c.82 0 1.486.663 1.486 1.48s-.665 1.48-1.486 1.48"
        opacity=".5"
      />
      <path
        fill="#5C6362"
        d="M168.49 156.896h-31.149a8.444 8.444 0 0 1-5.303-1.862c.09-.017.184-.026.28-.026h35.181v.901-.9h.991v1.887"
      />
    </g>
  </svg>
);
