import {
  format as DateFNSFormat,
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  differenceInDays,
  getYear,
  isAfter,
  isBefore,
  isValid,
  parse,
  parseISO,
  setDefaultOptions,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
} from 'date-fns';
import nl from 'date-fns/locale/nl';

setDefaultOptions({ locale: nl });

export enum DateUnit {
  'SECONDS',
  'MINUTES',
  'HOURS',
  'DAYS',
  'WEEKS',
  'MONTHS',
  'YEARS',
}

export enum DateFormat {
  DAY_WITH_DATE = 'eeee d MMMM yyyy',
  DATE = 'd MMMM yyyy',
  DATE_DASHES = 'dd-MM-yyyy',
  YEAR = 'yyyy',
  MONTH_YEAR = 'MMMM yyyy',
  TIME_FULL = 'HH:mm:ss',
  TIME = 'HH:mm',
  FULL_DATE = 'd MMMM yyyy HH.mm',
  DAY_MONTH = 'dd MMMM',
}

export const dateUtils = {
  format: (date: Date | number | string | undefined, dateFormat: string | DateFormat = DateFormat.DATE): string => {
    if (date === undefined) return 'Onbekend';

    let newDate = date;

    if (typeof date === 'string') {
      if (isValid(date)) {
        newDate = parse(date, dateFormat, new Date());
      } else {
        newDate = new Date(date);
      }
    }

    // If everything failed the date is unparseable, return Onbekend
    if (!isValid(newDate) || typeof newDate === 'string') {
      return 'Onbekend';
    }

    return DateFNSFormat(newDate, dateFormat);
  },
  getYear: (date: string | Date | number): number => {
    return typeof date === 'string' ? getYear(parseISO(date)) : getYear(date);
  },
  isBefore: (dateValue: string | Date, dateToCompare: Date = new Date(), defaultValue?: unknown): boolean | unknown => {
    if (typeof dateValue === 'string') {
      if (!isValid(dateValue)) {
        if (defaultValue !== undefined) {
          return defaultValue;
        }
        throw new Error('Invalid date passed');
      }
    }

    return isBefore(new Date(dateValue), dateToCompare);
  },
  isAfter: (dateValue: string | Date, dateToCompare: Date = new Date(), defaultValue?: unknown): boolean | unknown => {
    if (typeof dateValue === 'string') {
      if (!isValid(dateValue)) {
        if (defaultValue !== undefined) {
          return defaultValue;
        }
        throw new Error('Invalid date passed');
      }
    }

    return isAfter(new Date(dateValue), dateToCompare);
  },
  differenceInDays: (date: Date, dateToCompare: Date = new Date()): number => {
    return differenceInDays(date, dateToCompare);
  },
  add: (value: Date | string, amount: number, unit: DateUnit): Date => {
    const date: Date = typeof value === 'string' ? new Date(value) : value;

    switch (unit) {
      case DateUnit.SECONDS:
        return addSeconds(date, amount);
      case DateUnit.MINUTES:
        return addMinutes(date, amount);
      case DateUnit.HOURS:
        return addHours(date, amount);
      case DateUnit.DAYS:
        return addDays(date, amount);
      case DateUnit.WEEKS:
        return addWeeks(date, amount);
      case DateUnit.MONTHS:
        return addMonths(date, amount);
      case DateUnit.YEARS:
        return addYears(date, amount);
      default:
        return date;
    }
  },
  subtract: (date: Date, amount: number, unit: DateUnit): Date => {
    switch (unit) {
      case DateUnit.SECONDS:
        return subSeconds(date, amount);
      case DateUnit.MINUTES:
        return subMinutes(date, amount);
      case DateUnit.HOURS:
        return subHours(date, amount);
      case DateUnit.DAYS:
        return subDays(date, amount);
      case DateUnit.WEEKS:
        return subWeeks(date, amount);
      case DateUnit.MONTHS:
        return subMonths(date, amount);
      case DateUnit.YEARS:
        return subYears(date, amount);
      default:
        return date;
    }
  },
  isValid: (date: Date | number | string | undefined): boolean => {
    return isValid(date);
  },
  capitalizeMonth: (month = ''): string => {
    return month[0].toUpperCase() + month.slice(1);
  },
};
