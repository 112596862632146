import React from 'react';

export const ConstructionBarrier = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" viewBox="0 0 192 192">
    <g fill="none" transform="translate(19 40)">
      <path
        fill="#2F3031"
        d="M30.8,84.6246575 L123.2,84.6246575 L123.2,19.9969315 C123.2,18.3003128 124.575381,16.9249315 126.272,16.9249315 L132.448,16.9249315 C134.144619,16.9249315 135.52,18.3003128 135.52,19.9969315 L135.52,112.32526 C135.52,114.021879 134.144619,115.39726 132.448,115.39726 L123.2,115.39726 L123.2,93.8564384 L30.8,93.8564384 L30.8,115.39726 L21.552,115.39726 C19.8553812,115.39726 18.48,114.021879 18.48,112.32526 L18.48,19.9969315 C18.48,18.3003128 19.8553812,16.9249315 21.552,16.9249315 L27.728,16.9249315 C29.4246188,16.9249315 30.8,18.3003128 30.8,19.9969315 L30.8,84.6246575 Z"
      />
      <path
        fill="#000"
        d="M124.74,113.85863 L132.44,113.85863 C133.290519,113.85863 133.98,113.169762 133.98,112.32 L133.98,20.0021918 C133.98,19.1524298 133.290519,18.4635616 132.44,18.4635616 L126.28,18.4635616 C125.429481,18.4635616 124.74,19.1524298 124.74,20.0021918 L124.74,84.6246575 C124.74,85.4744195 124.050519,86.1632877 123.2,86.1632877 L30.8,86.1632877 C29.9494815,86.1632877 29.26,85.4744195 29.26,84.6246575 L29.26,20.0021918 C29.26,19.1524298 28.5705185,18.4635616 27.72,18.4635616 L21.56,18.4635616 C20.7094815,18.4635616 20.02,19.1524298 20.02,20.0021918 L20.02,112.32 C20.02,113.169762 20.7094815,113.85863 21.56,113.85863 L29.26,113.85863 L29.26,93.8564384 C29.26,93.0066764 29.9494815,92.3178082 30.8,92.3178082 L123.2,92.3178082 C124.050519,92.3178082 124.74,93.0066764 124.74,93.8564384 L124.74,113.85863 Z M32.34,83.0860274 L121.66,83.0860274 L121.66,20.0021918 C121.66,17.4529059 123.728444,15.3863014 126.28,15.3863014 L132.44,15.3863014 C134.991556,15.3863014 137.06,17.4529059 137.06,20.0021918 L137.06,112.32 C137.06,114.869286 134.991556,116.93589 132.44,116.93589 L123.2,116.93589 C122.349481,116.93589 121.66,116.247022 121.66,115.39726 L121.66,95.3950685 L32.34,95.3950685 L32.34,115.39726 C32.34,116.247022 31.6505185,116.93589 30.8,116.93589 L21.56,116.93589 C19.0084445,116.93589 16.94,114.869286 16.94,112.32 L16.94,20.0021918 C16.94,17.4529059 19.0084445,15.3863014 21.56,15.3863014 L27.72,15.3863014 C30.2715555,15.3863014 32.34,17.4529059 32.34,20.0021918 L32.34,83.0860274 Z"
      />
      <g transform="translate(0 26.157)">
        <path
          fill="#F9DA3E"
          d="M9.224,3.07726027 L150.92,3.07726027 L150.92,33.8603836 C150.92,37.2536211 148.169238,40.0043836 144.776,40.0043836 L3.08,40.0043836 L3.08,9.22126027 C3.08,5.82802277 5.8307625,3.07726027 9.224,3.07726027 Z"
        />
        <path
          fill="#000"
          d="M9.24,3.07726027 C5.83792594,3.07726027 3.08,5.83273298 3.08,9.23178082 L3.08,40.0043836 L144.76,40.0043836 C148.162074,40.0043836 150.92,37.2489109 150.92,33.849863 L150.92,3.07726027 L9.24,3.07726027 Z M9.24,0 L154,0 L154,33.849863 C154,38.9484348 149.863111,43.0816438 144.76,43.0816438 L0,43.0816438 L0,9.23178082 C-5.12923037e-16,4.13320906 4.13688891,1.02493356e-15 9.24,0 Z"
        />
        <path
          fill="#2F3031"
          d="M23.1,4.54747351e-13 L0.924,43.0816438 L0,43.0816438 L0,9.216 C-6.23327617e-16,4.12614375 4.12614375,4.57458699e-13 9.216,4.56523708e-13 L23.1,4.54747351e-13 Z M39.27,2.27373675e-13 L61.6,2.27373675e-13 L39.27,43.0816438 L16.94,43.0816438 L39.27,2.27373675e-13 Z M79.31,2.27373675e-13 L101.64,2.27373675e-13 L79.31,43.0816438 L56.98,43.0816438 L79.31,2.27373675e-13 Z M116.27,2.27373675e-13 L138.6,2.27373675e-13 L116.27,43.0816438 L93.94,43.0816438 L116.27,2.27373675e-13 Z M154,2.27373675e-13 L154,33.8656438 C154,38.9555001 149.873856,43.0816438 144.784,43.0816438 L132.44,43.0816438 L154,2.27373675e-13 Z"
        />
        <path
          fill="#000"
          d="M9.24,3.07726027 C5.83792594,3.07726027 3.08,5.83273298 3.08,9.23178082 L3.08,32.1646634 L18.0525543,3.07726027 L9.24,3.07726027 Z M1.9095836e-14,42.6068665 L0.924,43.0816438 L0.924,40.0043836 L-3.55826479e-14,40.0043836 L1.9095836e-14,42.6068665 Z M23.1,4.54747351e-13 L0.924,43.0816438 L3.40787911e-28,43.0816438 L3.40787911e-28,9.23178082 C-6.24950866e-16,4.13320906 4.13688891,4.55683943e-13 9.24,4.54747351e-13 L23.1,4.54747351e-13 Z M56.5365097,3.07726027 L41.1434903,3.07726027 L22.0034903,40.0043836 L37.3965097,40.0043836 L56.5365097,3.07726027 Z M39.27,2.27373675e-13 L61.6,2.27373675e-13 L39.27,43.0816438 L16.94,43.0816438 L39.27,2.27373675e-13 Z M81.1834903,3.07726027 L62.0434903,40.0043836 L77.4365097,40.0043836 L96.5765097,3.07726027 L81.1834903,3.07726027 Z M79.31,2.27373675e-13 L101.64,2.27373675e-13 L79.31,43.0816438 L56.98,43.0816438 L79.31,2.27373675e-13 Z M118.14349,3.07726027 L99.0034903,40.0043836 L114.39651,40.0043836 L133.53651,3.07726027 L118.14349,3.07726027 Z M116.27,2.27373675e-13 L138.6,2.27373675e-13 L116.27,43.0816438 L93.94,43.0816438 L116.27,2.27373675e-13 Z M150.92,13.0354837 L137.423545,40.0043836 L144.76,40.0043836 C148.162074,40.0043836 150.92,37.2489109 150.92,33.849863 L150.92,13.0354837 Z M154,2.27373675e-13 L154,33.849863 C154,38.9484348 149.863111,43.0816438 144.76,43.0816438 L132.44,43.0816438 L154,2.27373675e-13 Z"
        />
      </g>
      <g transform="translate(13.86)">
        <path
          fill="#F48C00"
          d="M10.756,1.53863014 L10.804,1.53863014 C15.8938563,1.53863014 20.02,5.66477388 20.02,10.7546301 L20.02,20.0021918 L1.54,20.0021918 L1.54,10.7546301 C1.54,5.66477388 5.66614375,1.53863014 10.756,1.53863014 Z"
        />
        <path
          fill="#C37000"
          d="M18.48,18.4635616 L18.48,10.770411 C18.48,6.52160116 15.0325926,3.07726027 10.78,3.07726027 C6.52740743,3.07726027 3.08,6.52160116 3.08,10.770411 L3.08,18.4635616 L18.48,18.4635616 Z M10.78,0 C16.7336296,0 21.56,4.82207724 21.56,10.770411 L21.56,21.5408219 L0,21.5408219 L0,10.770411 C0,4.82207724 4.8263704,0 10.78,0 Z"
        />
        <path
          fill="#FFF"
          fillOpacity=".4"
          d="M15.4,15.3889315 L15.4,11.539726 C15.4,8.56555917 12.9868148,6.15452055 10.01,6.15452055 C7.0331852,6.15452055 4.62,8.56555917 4.62,11.539726 L4.62,10.770411 C4.62,7.37136312 7.37792594,4.61589041 10.78,4.61589041 C14.1820741,4.61589041 16.94,7.37136312 16.94,10.770411 L16.94,16.9249315 L16.936,16.9249315 C16.0876906,16.9249315 15.4,16.2372409 15.4,15.3889315 Z"
        />
      </g>
      <g transform="translate(118.58)">
        <path
          fill="#F48C00"
          d="M10.756,1.53863014 L10.804,1.53863014 C15.8938563,1.53863014 20.02,5.66477388 20.02,10.7546301 L20.02,20.0021918 L1.54,20.0021918 L1.54,10.7546301 C1.54,5.66477388 5.66614375,1.53863014 10.756,1.53863014 Z"
        />
        <path
          fill="#C37000"
          d="M18.48,18.4635616 L18.48,10.770411 C18.48,6.52160116 15.0325926,3.07726027 10.78,3.07726027 C6.52740743,3.07726027 3.08,6.52160116 3.08,10.770411 L3.08,18.4635616 L18.48,18.4635616 Z M10.78,0 C16.7336296,0 21.56,4.82207724 21.56,10.770411 L21.56,21.5408219 L0,21.5408219 L0,10.770411 C0,4.82207724 4.8263704,0 10.78,0 Z"
        />
        <path
          fill="#FFF"
          fillOpacity=".4"
          d="M15.4,15.3889315 L15.4,11.539726 C15.4,8.56555917 12.9868148,6.15452055 10.01,6.15452055 C7.0331852,6.15452055 4.62,8.56555917 4.62,11.539726 L4.62,10.770411 C4.62,7.37136312 7.37792594,4.61589041 10.78,4.61589041 C14.1820741,4.61589041 16.94,7.37136312 16.94,10.770411 L16.94,16.9249315 L16.936,16.9249315 C16.0876906,16.9249315 15.4,16.2372409 15.4,15.3889315 Z"
        />
      </g>
    </g>
  </svg>
);
