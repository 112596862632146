import { BenefitBrand, TvBenefitType } from 'api';

export enum TvBenefitEligibilityEnum {
  ALLOWED = 'ALLOWED',
  BUNDLECHANGEREQUIRED = 'BUNDLE_CHANGE_REQUIRED',
  CONTENTOVERLAP = 'CONTENT_OVERLAP',
  CANCELLATION = 'CANCELLATION',
  NEWORDER = 'NEW_ORDER',
}

export interface TvBenefit {
  televisionPackageId: string;
  name: TvBenefitType;
  brand: BenefitBrand;
  eligibility: TvBenefitEligibilityEnum;
  eligibilityReason?: string | null;
}

export interface AssignedBenefit {
  changeAllowedAfterDate: string;
  benefit: TvBenefit;
}

export interface PendingTvBenefitName {
  name: string;
}
