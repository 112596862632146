import { EmailAddressInfo, EmailStatus } from 'api';
import { DevicesInternet } from 'assets/img/DevicesInternet';
import { ChildPage } from 'components/ChildPage/ChildPage';
import * as ContentContainer from 'components/ContentContainer';
import { Col, Row } from 'components/Grid';
import { Link } from 'components/Link';
import { PageHeader } from 'components/PageHeader';
import { useEmailContext } from 'context/email/email.context';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ContentUtil } from 'utils/content';

import SectionAlias from './components/SectionAlias/index';
import SectionEmail from './components/SectionEmail/index';
import SectionPassword from './components/SectionPassword/index';
import { content } from './email-settings.content';
import * as S from './email-settings.styles';

const contentUtil = new ContentUtil({ content, componentName: 'EmailSettings' });

const parents = [
  {
    to: '/producten',
    label: contentUtil.translateToString('parents.products'),
  },
  {
    to: '/producten/internet',
    label: contentUtil.translateToString('parents.internet'),
  },
];

export const EmailSettings = () => {
  const params = useParams();
  const [emailAddress, setEmailAddress] = useState<EmailAddressInfo | null>(null);
  const emailAddressIndex = params.index;
  const { emailAddresses, maxAliasesPerEmailBoxAllowed } = useEmailContext();

  useEffect(() => {
    // only initially set an emailaddress. After it has been set, keep that one
    // Reason: when not doing this and we'll delete the email address, it the state will be updated with the next
    // email in the array (or throw an error if you've deleted the last one). Thus remember what address was deleted.
    if (emailAddresses && !emailAddress) {
      setEmailAddress(emailAddresses[Number(emailAddressIndex)]);
    }
  }, [emailAddresses]);

  return (
    <ChildPage>
      <Helmet>
        <title>{contentUtil.translateToString('pageTitle')}</title>
      </Helmet>
      <PageHeader title={contentUtil.translateToString('pageTitle')} parents={parents} />
      <S.Wrapper>
        <Row>
          {emailAddress && emailAddress.status !== EmailStatus.ENABLED && (
            <Col m={9}>
              <S.InlineAlert type="error" testid="email-disabled-error">
                <strong>
                  {contentUtil.translateToString(
                    `emailDisabled.${emailAddress.aliases.length ? 'hasAliases' : 'default'}`
                  )}
                </strong>
                <p>{contentUtil.translateToString('emailDisabled.subHeader')}</p>
              </S.InlineAlert>
            </Col>
          )}
          <Col m={9}>
            <ContentContainer.ContentCard>
              {emailAddress && (
                <>
                  <S.SectionEmail>
                    <SectionEmail emailAddress={emailAddress.emailAddress} emailAliases={emailAddress.aliases} />
                  </S.SectionEmail>
                  <S.SectionDivider />
                  <S.SectionAliases>
                    <SectionAlias
                      emailAddress={emailAddress.emailAddress}
                      aliases={emailAddress.aliases}
                      maxAliasesPerEmailBoxAllowed={maxAliasesPerEmailBoxAllowed}
                    />
                  </S.SectionAliases>
                  <S.SectionDivider />
                  <S.SectionPassword>
                    <SectionPassword emailAddress={emailAddress.emailAddress} />
                  </S.SectionPassword>
                </>
              )}
            </ContentContainer.ContentCard>
          </Col>
          <Col m={3}>
            <ContentContainer.SideCard>
              <S.SideCardImage>
                <DevicesInternet />
              </S.SideCardImage>
              <S.SideCardHeader>{contentUtil.translate('sideCard.header')}</S.SideCardHeader>
              <S.SideCardContent>
                <span>{contentUtil.translate('sideCard.content')}</span>
                <p>
                  <Link
                    external
                    data-testid="ziggo-mail-guide"
                    href={contentUtil.translateToString('sideCard.linkUrl')}>
                    {contentUtil.translate('sideCard.linkText')}
                  </Link>
                </p>
              </S.SideCardContent>
            </ContentContainer.SideCard>
          </Col>
        </Row>
      </S.Wrapper>
    </ChildPage>
  );
};

export default EmailSettings;
