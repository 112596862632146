import { ConversationalMessageProductTypesEnum } from 'api';
import { Card } from 'components/Card';
import { useOutageContext } from 'context/outage/outage.context';
import _ from 'lodash';
import React from 'react';
import { ContentUtil } from 'utils';
import { dateUtils } from 'utils/date';

import IOutageCardProps from './Interface/IOutageCardProps';
import OutageSubscribeForm from './OutageSubscribeForm';
import OutageUnsubscribeForm from './OutageUnsubscribeForm';
import { content as conversationContent } from './conversation.content';
import { content } from './outage-overview.content';
import * as Styles from './outage-overview.styles';

const contentUtil = new ContentUtil({ content, componentName: 'OutageOverview' });
const conversationContentUtil = new ContentUtil({ content: conversationContent, componentName: 'OutageOverview' });

const OutageCard = (props: IOutageCardProps) => {
  const { unsubscribeOutage, subscribeOutage } = useOutageContext();

  const translateProductTypes = (types?: Array<ConversationalMessageProductTypesEnum> | null): string => {
    if (!types) return '';

    // Make sure we only show Ziggo TV once.
    if (
      types.includes(ConversationalMessageProductTypesEnum.CATV) &&
      types.includes(ConversationalMessageProductTypesEnum.DIGITALTV)
    ) {
      types.splice(types.indexOf(ConversationalMessageProductTypesEnum.CATV), 1);
    }

    return types.map((type) => conversationContentUtil.translateToString(`productType.${type}`)).join(', ');
  };

  const translateDate = (date?: string | null): string => {
    if (!date) return contentUtil.translateToString('unknown');
    return _.capitalize(dateUtils.format(date, "d LLLL yyyy 'om' HH:mm"));
  };

  const renderForm = () => {
    return props.outage.subscribed ? (
      <OutageUnsubscribeForm subscribedOutage={props.outage} onSubmit={unsubscribeOutage} />
    ) : (
      <OutageSubscribeForm outage={props.outage} onSubmit={subscribeOutage} />
    );
  };

  return (
    <Card>
      {props.showHeader !== false && (
        <>
          <Styles.SubHeader>
            <h4>{contentUtil.translateToString('subHeader')}</h4>
            <p>{contentUtil.translateToString('subHeaderText')}</p>
          </Styles.SubHeader>
          <Styles.Divider />
        </>
      )}

      {props.outage && (
        <>
          <Styles.InfoContainer>
            <strong>{contentUtil.translateToString('outageType')}</strong>
            <p data-testid="outage-impacted-products-value">{translateProductTypes(props.outage?.impactedProducts)}</p>

            <strong>{contentUtil.translateToString('outageStart')}</strong>
            <p>{translateDate(props.outage?.creationDate)}</p>

            <strong>{contentUtil.translateToString('outageEnd')}</strong>
            <p>{translateDate(props.outage?.expectedSolveDate)}</p>
          </Styles.InfoContainer>

          {(props.isSubscribeFormEnabled || true) && (
            <>
              <Styles.Divider />
              <Styles.FormContainer>
                <h5>{contentUtil.translateToString('formTitle')}</h5>
                <strong>{contentUtil.translateToString('formSubtitle')}</strong>

                {renderForm()}
              </Styles.FormContainer>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default OutageCard;
