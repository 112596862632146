import React, { SVGProps } from 'react';

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0,7.45505288 L9.30868687,15.5729856 L0,24.0102644 L0,7.45505288 Z M28.3068687,6 L14.9361616,17.4875096 L1.60787879,6 L28.3068687,6 Z M16.4593939,19.057476 C16.8825253,18.7128269 19.4636364,16.5302019 19.4636364,16.5302019 L28.8146465,25 L1.01545455,25 L10.4087879,16.5302019 C10.4087879,16.5302019 12.989899,18.7128269 13.4129293,19.057476 C14.5553535,19.9751394 15.3169697,19.9751394 16.4593939,19.057476 Z M20.5637374,15.5729856 L30,7.41677885 L30,24.0863558 L20.5637374,15.5729856 Z"
    />
  </svg>
);
