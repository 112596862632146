import { FeatureToggle } from 'components/FeatureToggle';
import { Restricted } from 'components/Restricted';
import { PRODUCTS, USAGE } from 'config/access-state-features.config';
import { appSettings } from 'config/app.settings';
import { useProductContext } from 'context/product/product.context';
import { FEATURES } from 'models/features.model';
import React from 'react';
import { ContentUtil } from 'utils';

import { content } from '../../base-layout.content';
import * as S from './navigation-mobile.styles';

const contentUtil = new ContentUtil({ content, componentName: 'NavigationMobile' });

interface INavigationMobileProps {
  isMerged: boolean;
  onClickLogout(): void;
}

const NavigationMobileBase = ({ isMerged, onClickLogout }: INavigationMobileProps) => {
  const { hasGroupProduct } = useProductContext();

  return (
    <S.NavigationMobile>
      <S.NavigationLink to="/overzicht">
        {contentUtil.translate('dashboard')}
        <S.Chevron />
      </S.NavigationLink>
      <Restricted permissions={PRODUCTS}>
        {hasGroupProduct() && (
          <S.NavigationLink to="/producten">
            {contentUtil.translate('products')}
            <S.Chevron />
          </S.NavigationLink>
        )}
      </Restricted>
      <S.NavigationLink to="/gegevens">
        {contentUtil.translate('contactDetails')}
        <S.Chevron />
      </S.NavigationLink>
      <S.NavigationLink to="/facturen">
        {contentUtil.translate('invoices')}
        <S.Chevron />
      </S.NavigationLink>
      <Restricted permissions={USAGE}>
        <S.NavigationLink to="/verbruik">
          {contentUtil.translate('usage')}
          <S.Chevron />
        </S.NavigationLink>
      </Restricted>
      <FeatureToggle feature={FEATURES.PAGE_ENTERTAINMENT}>
        <S.NavigationLink to="/entertainment">
          {contentUtil.translate('entertainment')}
          <S.Chevron />
        </S.NavigationLink>
      </FeatureToggle>
      <FeatureToggle feature={FEATURES.SERVICE_PAGE}>
        <S.NavigationLink
          as="a"
          href={
            appSettings.ENV_REDIRECT_URI
              ? `${appSettings.ENV_REDIRECT_URI}/service`
              : `${appSettings.ENV_ZIGGO_URL}/mijn-ziggo/service`
          }>
          {contentUtil.translate('service')}
          <S.Chevron />
        </S.NavigationLink>
      </FeatureToggle>
      <S.Hr />
      <S.ExternalLink href={`${appSettings.ENV_ZIGGO_ACCOUNT_URL}/account/verander-login`}>
        {contentUtil.translate('changeLogin')}
        <S.Chevron />
      </S.ExternalLink>
      <S.ExternalLink href={`${appSettings.ENV_ZIGGO_ACCOUNT_URL}/account/wachtwoord-wijzigen`}>
        {contentUtil.translate('changePassword')}
        <S.Chevron />
      </S.ExternalLink>
      <S.ExternalLink href={`${appSettings.ENV_ZIGGO_ACCOUNT_URL}/account`}>
        {contentUtil.translate('myAccount')}
        <S.Chevron />
      </S.ExternalLink>
      {isMerged && (
        <S.ExternalLink href={`${appSettings.ENV_VODAFONE_URL}/my`}>
          {contentUtil.translate('myVodafone')}
          <S.Chevron />
        </S.ExternalLink>
      )}
      <S.LogoutButton onClick={onClickLogout}>
        {contentUtil.translate('logout')}
        <S.Chevron />
      </S.LogoutButton>
    </S.NavigationMobile>
  );
};

export const NavigationMobile = NavigationMobileBase;
