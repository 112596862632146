import React, { SVGProps } from 'react';

export const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="11" height="7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7" {...props}>
    <path
      d="M10.532 0a1.473 1.473 0 0 1 0 2.154L5.5 7 .468 2.154A1.473 1.473 0 0 1 .468 0L5.5 4.846 10.532 0z"
      fillRule="evenodd"
    />
  </svg>
);
