import { gql } from 'graphql-request';

export const getOrder = gql`
  query getOrder($billingCustomerId: String!, $orderId: String!) {
    getOrder(billingCustomerId: $billingCustomerId, orderId: $orderId) {
      address {
        id
        postCode
        city
        country
        streetName
        houseNumber
        houseNumberExtension
        roomNumber
      }
      contact {
        birthDate
        email
        firstName
        id
        initials
        lastName
        middleName
        phone
        salutation
      }
      creationDate
      startDate
      id
      numberPorting {
        phoneNumber
        portingStatus
      }
      orderDelivery {
        deliveryMethod
        trackAndTrace
        address {
          id
          postCode
          city
          country
          streetName
          houseNumber
          houseNumberExtension
          roomNumber
        }
      }
      orderLines {
        action
        category
        onetimeCost {
          excludingVat
          includingVat
        }
        productName
        recurringCost {
          excludingVat
          includingVat
        }
      }
      orderMove {
        moveAddress {
          id
          postCode
          city
          country
          streetName
          houseNumber
          houseNumberExtension
          roomNumber
        }
        moveDate
        phoneNumberChanges
      }
      orderStatus
      providerTransfer {
        provider
        transferStatus
      }
      totalOnetime {
        excludingVat
        includingVat
      }
      totalRecurring {
        excludingVat
        includingVat
      }
      pointOfNoReturnPassed
      acquisitionOrder
    }
  }
`;

export const getOrders = gql`
  query getOrderList($billingCustomerId: String!, $limit: Float, $dateFrom: String) {
    getOrderList(billingCustomerId: $billingCustomerId, limit: $limit, dateFrom: $dateFrom) {
      creationDate
      id
      orderStatus
    }
  }
`;

export const getOrderValidDateList = gql`
  query getOrderValidDateList($billingCustomerId: String!, $orderId: String!) {
    getOrderValidDateList(billingCustomerId: $billingCustomerId, orderId: $orderId) {
      validDateList
    }
  }
`;

export const mutateActivationDateQuery = gql`
  mutation mutateActivationDate($billingCustomerId: String!, $orderId: String!, $activationDate: String!) {
    mutateActivationDate(billingCustomerId: $billingCustomerId, orderId: $orderId, activationDate: $activationDate) {
      success
    }
  }
`;

export const mutateDeliveryAddressQuery = gql`
  mutation mutateDeliveryAddress($billingCustomerId: String!, $orderId: String!, $deliveryAddressId: String!) {
    mutateDeliveryAddress(
      billingCustomerId: $billingCustomerId
      orderId: $orderId
      deliveryAddressId: $deliveryAddressId
    ) {
      success
    }
  }
`;
