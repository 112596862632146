export enum FEATURES {
  // The following Features are Workarounds
  CHANGE_ACTIVATION_DATE = 'change_activation_date',
  CHANGE_DELIVERY_ADDRESS = 'change_delivery_address',
  CHANGE_INSTALLATION_ADDRESS = 'change_installation_address',
  CHANGE_PRODUCT = 'change_product',
  CHANGE_PRODUCT_TV = 'change_product_tv',
  CHANGE_TV_PACKAGES = 'change_tv_packages',
  CHANGE_RECEIVERS = 'change_receivers',
  CHANGE_PRODUCT_INTERNET = 'change_product_internet',
  CHANGE_INTERNET_EQUIPMENT = 'change_internet_equipment',
  CHANGE_SAFE_ONLINE = 'change_safe_online',
  CHANGE_EXTRA_SERVICES = 'change_extra_services',
  // Disables the following pages entirely
  PAGE_DASHBOARD = 'page_dashboard',
  PAGE_INVOICES = 'page_invoices',
  PAGE_USAGE = 'page_usage',
  PAGE_PRODUCTS = 'page_products',
  PAGE_CONTACT_DETAILS = 'page_contact_details',
  // Disable the wifi spots on the internet Products page
  CHANGE_WIFISPOTS = 'change_wifispots',
  // Not to be confused with change_safe_online. This disabes the entire feature
  SAFE_ONLINE = 'safe_online',
  EMAIL = 'email',
  REFRESH_ENTITLEMENTS = 'refresh_entitlements',
  RESET_PINCODE = 'reset_pincode',
  ORDER_DAY_PASS = 'order_day_pass',
  CONNECTCLOUD_LINK = 'connectcloud_link',
  WORKAROUND_COMMUNNITY = 'workaround_community',
  WIFI_SPOTS = 'wifi_spots',
  APP_BILLING = 'app_billing',
  MAINTENANCE = 'maintenance',
  GURU_ENABLED = 'guru_enabled',
  STREAMING_USAGE = 'streaming_usage',
  STREAMING_PRODUCT_CARD = 'streaming_product_card',
  STREAMING_IN_TV_PACKAGES = 'streaming_in_tv_packages',
  CHANGE_INVOICE_TYPE = 'change_invoice_type',
  // Disable outages functionality in case of K2View instability
  OUTAGES_SUBSCRIPTION_ENABLED = 'outages_subscription_enabled',
  PAYMENT_ARRANGEMENT_ENABLED = 'payment_arrangement_enabled',
  FILM_RENTAL = 'film_rental_nba',
  STREAMING_SKYSHOWTIME_ENABLED = 'streaming_skyshowtime_enabled',
  STREAMING_VIDEOLAND_ENABLED = 'streaming_videoland_enabled',
  SERVICE_PAGE = 'service_page',
  PAGE_ENTERTAINMENT = 'page_entertainment',

  CEASED_EMAIL = 'ceased_email',
  INVOICE_HELP_VIDEO = 'invoice_help_video',
}

export enum FEATURE_STATES {
  DEFAULT = 'on',
  ON = 'on',
  OFF = 'off',
  REDIRECT = 'redirect',
}
