import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    close: 'SLUITEN',
    home: 'ZIGGO.NL',
    search: 'ZOEKEN',
    ziggoMail: 'ZIGGO MAIL',
    phone: '0900-1884',
    secureConnection: 'Veilige verbinding',
    overview: 'Overzicht'
  },
  en: {},
};
