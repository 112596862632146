import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    emailHeader: 'E-mailadres',
    deleteEmailAddress: 'E-mailadres verwijderen',

    confirmModal: {
      header: 'Weet je het zeker?',
      content: `
         <p>
           Als je dit e-mailadres verwijdert, is het ook echt weg en ben je alle e-mails in je inbox kwijt.
         </p>
     `,
      contentRed: `
         <p>
           <strong>#{emailAddress} definitief verwijderen?</strong>
         </p>
     `,
      contentAliases: `
        <p>
          Je verwijdert hiermee ook je aliassen #{emailAliasList}
        </p>
      `,
      confirmButton: 'Ja, verwijderen',
      cancelButton: 'Toch maar niet',
    },

    removalFailedModal: {
      header: 'Niet gelukt...',
      content: `
         <p>
         Het lukt niet om dit e-mailadres te verwijderen, sorry. Probeer het later nog eens.
         </p>
     `,
      button: 'Ok',
    },

    removalSuccessModal: {
      header: 'Gelukt!',
      content: `
         <p>
           Je e-mailadres <strong>#{emailAddress}</strong> is verwijderd.
         </p>
     `,
      button: 'Naar E-mail instellen',
    },
  },
  en: {},
};
