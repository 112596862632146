import { throttle } from 'lodash';

type ISizeMatcher = (width: number, height: number) => boolean;
type IResizeCallback = (matches: boolean) => void;
type IResizeListener = {
  callback: IResizeCallback;
  matcher: ISizeMatcher;
  matches: boolean | null;
};

class DocumentEvents {
  private escKeyEvents: EventListener[] = [];

  constructor() {
    window.addEventListener('resize', throttle(this.validateResizeListeners, 200));
    window.addEventListener('rotationchange', throttle(this.validateResizeListeners, 200));

    document.addEventListener(
      'keydown',
      (e) => {
        this.escFunction(e, this.escKeyEvents);
      },
      false
    );
  }

  public bodyListener(callback: EventListener) {
    document.body.addEventListener('click', callback);
  }

  public removeBodyListener(callback: EventListener) {
    document.body.removeEventListener('click', callback);
  }

  public escapeListener(callback: EventListener) {
    this.escKeyEvents.push(callback);
  }

  public removeEscapeListener(callback: EventListener) {
    this.escKeyEvents = this.escKeyEvents.filter((event) => event !== callback);
  }

  /**
   * Add a callback which fires when window width or height does or doesn't
   * match a specific size anymore.
   *
   * @param matcher Method that receives current height and width and should return
   * a boolean true if it matches.
   * @param callback Method fired with a boolean as parameter which tells if it matches or not.
   */
  public resizeListener = (matcher: ISizeMatcher, callback: IResizeCallback) => {
    // Make sure events are not added multiple times.
    this.removeResizeListener(callback);

    this.resizeListenersArray.push({
      callback,
      matcher,
      matches: null,
    });

    // Kickoff matches an extra time to get initial state for newly listener.
    this.validateResizeListeners();
  };

  public removeResizeListener(callback: IResizeCallback) {
    this.resizeListenersArray = this.resizeListenersArray.filter((listener) => listener.callback !== callback);
  }

  private resizeListenersArray: IResizeListener[] = [];

  /**
   * Loops through all listeners and checks if they match.
   * Will only notify once if it changes from match to no match and vice versa.
   */
  private validateResizeListeners = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.resizeListenersArray.forEach((listener) => {
      const matches = listener.matcher(width, height);
      if (listener.matches === matches) return;

      // eslint-disable-next-line no-param-reassign
      listener.matches = matches;
      listener.callback(matches);
    });
  };

  private escFunction(event: KeyboardEvent, actions: EventListener[]) {
    if (event.keyCode === 27) {
      actions.forEach((action) => action(event));
    }
  }
}

export default new DocumentEvents();
