import { gql } from 'graphql-request';

export const getDashboardInfoQuery = gql`
  query getDashboardInfo($billingCustomerId: String!) {
    getDashboardInfo(billingCustomerId: $billingCustomerId) {
      benefitInfo {
        beneficialBrand
        selectedSafeOnlineBenefit
        selectedTVBenefit
      }
      companyName
      customerNumber
      customerSubType
      productType
      email
      firstName
      installationAddress {
        city
        houseNumber
        houseNumberExtension
        postCode
        roomNumber
        streetName
      }
      lastName
      middleName
      primaryPhone
      productCategories {
        internet
        telephony
        television
      }
    }
  }
`;

export const getDashboardInfoV2Query = gql`
  query getDashboardInfoV2($billingCustomerId: String!) {
    getDashboardInfoV2(billingCustomerId: $billingCustomerId) {
      benefitInfo {
        beneficialBrand
        selectedSafeOnlineBenefit
        selectedTVBenefit
      }
      companyName
      customerNumber
      customerSubType
      productType
      email
      firstName
      installationAddress {
        city
        houseNumber
        houseNumberExtension
        postCode
        roomNumber
        streetName
      }
      lastName
      middleName
      primaryPhone
      productCategories {
        internet
        telephony
        television
        catv
        dtv
      }
      appointmentInfo {
        startDateTime
        endDateTime
      }
    }
  }
`;
