import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    pageTitle: 'Storingen',
    subHeader: 'Er is een storing in je buurt',
    subHeaderText: 'We werken aan een oplossing, je hoeft nu zelf niets te doen.',

    outageType: 'Soort storing:',
    outageStart: 'Storing vanaf:',
    outageEnd: 'Wanneer is het opgelost?',
    unknown: 'Onbekend',

    formTitle: 'Wil je een sms-bericht wanneer deze storing is opgelost?',
    formSubtitle: 'Mobiele nummer(s) waarop je de storingsmelding ontvangt:',
    phoneNumberLabel: 'Mobiele nummer:',
    subscribe: 'Nummer(s) opslaan',
    unsubscribe: 'Zet sms-berichten uit',
    addNumber: 'Extra nummer toevoegen. Max.10',

    error: {
      noOutageId: 'Er is iets misgegaan en je kunt op dit moment niet aanmelden. Probeer het later nog eens.',
      noOutages: 'Er is geen storing of gepland onderhoud in je buurt.',
      subscribeOutage: 'Er is iets misgegaan en nu kunnen we je nummer niet toevoegen. Probeer het later nog eens.',
      unsubscribeOutage: 'Er is iets misgegaan en nu kunnen we je nummer niet afmelden. Probeer het later nog eens.',
    },

    unsubscribeModal: {
      confirm: 'Zet uit',
      cancel: 'Toch niet',
      content: 'Dit geldt voor alle mobiele nummers die je hebt ingesteld',
      header: 'Weet je zeker dat je sms-berichten wilt uitzetten?',
    },
    subscribeSuccessModal: {
      confirm: 'Top, sluit scherm',
      content: 'We sturen een sms wanneer de status van de storing wijzigt.',
      header: 'Gelukt!',
    },
    unsubscribeSuccessModal: {
      confirm: 'Sluit scherm',
      content: 'We sturen geen sms over de status van de storing.',
      header: 'Gelukt, sms-berichten staat uit.',
    },
  },
  en: {},
};
