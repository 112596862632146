import * as url from 'url';

import { AxiosPromise } from 'axios';

import RequiredError from 'error/RequiredError';
import { ApiResponse, query } from './api';
import ApiFactory from './api-factory';
import { getBillingCustomerQuery, getBillingCustomerListQuery } from './billingcustomer.query';
import { BillingCustomer } from './billingcustomer.types';
import { ComponentIdentifier } from './component-identifier.types';

interface BillingCustomerApiInterface {
  componentIdentifier(
    billingCustomerId: string,
    componentIdentifier:
      | 'PRIVACY_COMPLAINT'
      | 'PRIVACY_REQUEST'
      | 'PRIVACY_DELETE'
      | 'CALL_BARS'
      | 'RESET_PINCODE'
      | 'REFRESH_ENTITLEMENTS'
      | 'WIFI_SPOT_STATUS'
      | 'CREATE_WIFI_SPOT'
      | 'SECRET_CIRCLE'
      | 'CHANGE_PHONE_NUMBER'
      | 'PHONE_SETTINGS'
  ): AxiosPromise<ComponentIdentifier>;

  /** Check if this component is available for a specific product. For example, we use this to determine whether or not certain elements in the frontend should be enabled or disabled. */
  componentIdentifierForProduct(
    billingCustomerId: string,
    componentIdentifier:
      | 'PRIVACY_COMPLAINT'
      | 'PRIVACY_REQUEST'
      | 'PRIVACY_DELETE'
      | 'CALL_BARS'
      | 'RESET_PINCODE'
      | 'REFRESH_ENTITLEMENTS'
      | 'WIFI_SPOT_STATUS'
      | 'CREATE_WIFI_SPOT'
      | 'SECRET_CIRCLE'
      | 'CHANGE_PHONE_NUMBER'
      | 'PHONE_SETTINGS',
    assignedProductId: string
  ): AxiosPromise<ComponentIdentifier>;
  /** Get basic info of a billing customer. */
  getBillingCustomer(billingCustomerId: string): ApiResponse<BillingCustomer>;

  /** Get basic info of all billing customers the user has access to. */
  getBillingCustomers(billingCustomerId: string): ApiResponse<BillingCustomer[]>;
}

export const BillingCustomerApi: BillingCustomerApiInterface = {
  componentIdentifierForProduct: (
    billingCustomerId: string,
    componentIdentifier:
      | 'PRIVACY_COMPLAINT'
      | 'PRIVACY_REQUEST'
      | 'PRIVACY_DELETE'
      | 'CALL_BARS'
      | 'RESET_PINCODE'
      | 'REFRESH_ENTITLEMENTS'
      | 'WIFI_SPOT_STATUS'
      | 'CREATE_WIFI_SPOT'
      | 'SECRET_CIRCLE'
      | 'CHANGE_PHONE_NUMBER'
      | 'PHONE_SETTINGS',
    assignedProductId: string,
    options: any = {}
  ): AxiosPromise => {
    // verify required parameter 'billingCustomerId' is not null or undefined
    if (billingCustomerId === null || billingCustomerId === undefined) {
      throw new RequiredError(
        'billingCustomerId',
        'Required parameter billingCustomerId was null or undefined when calling componentIdentifierForProduct.'
      );
    }
    // verify required parameter 'componentIdentifier' is not null or undefined
    if (componentIdentifier === null || componentIdentifier === undefined) {
      throw new RequiredError(
        'componentIdentifier',
        'Required parameter componentIdentifier was null or undefined when calling componentIdentifierForProduct.'
      );
    }
    // verify required parameter 'assignedProductId' is not null or undefined
    if (assignedProductId === null || assignedProductId === undefined) {
      throw new RequiredError(
        'assignedProductId',
        'Required parameter assignedProductId was null or undefined when calling componentIdentifierForProduct.'
      );
    }

    const localVarQueryParameter = {} as any;

    const localVarPath =
      `/selfservice/rest/v1/billing-customers/{billingCustomerId}/component/{componentIdentifier}/assigned-product/{assignedProductId}`
        .replace(`{${'billingCustomerId'}}`, encodeURIComponent(String(billingCustomerId)))
        .replace(`{${'componentIdentifier'}}`, encodeURIComponent(String(componentIdentifier)))
        .replace(`{${'assignedProductId'}}`, encodeURIComponent(String(assignedProductId)));
    const localVarUrlObj = url.parse(localVarPath, true);
    const method = 'GET';

    const contentType = 'application/json';
    let data;

    localVarUrlObj.query = (<any>Object).assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
    return ApiFactory.getInstance()
      .getApi()
      .api({
        ...options,
        url: url.format(localVarUrlObj),
        method,
        data,
        headers: {
          'Content-Type': contentType,
        },
      });
  },

  componentIdentifier: (
    billingCustomerId: string,
    componentIdentifier:
      | 'PRIVACY_COMPLAINT'
      | 'PRIVACY_REQUEST'
      | 'PRIVACY_DELETE'
      | 'CALL_BARS'
      | 'RESET_PINCODE'
      | 'REFRESH_ENTITLEMENTS'
      | 'WIFI_SPOT_STATUS'
      | 'CREATE_WIFI_SPOT'
      | 'SECRET_CIRCLE'
      | 'CHANGE_PHONE_NUMBER'
      | 'PHONE_SETTINGS',
    options: any = {}
  ): AxiosPromise => {
    // verify required parameter 'billingCustomerId' is not null or undefined
    if (billingCustomerId === null || billingCustomerId === undefined) {
      throw new RequiredError(
        'billingCustomerId',
        'Required parameter billingCustomerId was null or undefined when calling componentIdentifier.'
      );
    }
    // verify required parameter 'componentIdentifier' is not null or undefined
    if (componentIdentifier === null || componentIdentifier === undefined) {
      throw new RequiredError(
        'componentIdentifier',
        'Required parameter componentIdentifier was null or undefined when calling componentIdentifier.'
      );
    }

    const localVarQueryParameter = {} as any;

    const localVarPath = `/selfservice/rest/v1/billing-customers/{billingCustomerId}/component/{componentIdentifier}`
      .replace(`{${'billingCustomerId'}}`, encodeURIComponent(String(billingCustomerId)))
      .replace(`{${'componentIdentifier'}}`, encodeURIComponent(String(componentIdentifier)));
    const localVarUrlObj = url.parse(localVarPath, true);
    const method = 'GET';

    const contentType = 'application/json';
    let data;

    localVarUrlObj.query = (<any>Object).assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
    return ApiFactory.getInstance()
      .getApi()
      .api({
        ...options,
        url: url.format(localVarUrlObj),
        method,
        data,
        headers: {
          'Content-Type': contentType,
        },
      });
  },

  getBillingCustomers(billingCustomerId = '') {
    return query({
      query: getBillingCustomerListQuery,
      variables: { billingCustomerId },
      name: 'getBillingCustomerList',
    }).then(({ getBillingCustomerList }) => {
      return {
        data: getBillingCustomerList,
      };
    });
  },

  getBillingCustomer(billingCustomerId = '') {
    return query({
      query: getBillingCustomerQuery,
      variables: { billingCustomerId },
      name: 'getBillingCustomer',
    }).then(({ getBillingCustomer, getBillingCustomerPermissions }) => {
      const { hasSecondPhone } = getBillingCustomerPermissions;

      return {
        data: {
          ...getBillingCustomer,
          primaryContact: {
            ...getBillingCustomer.primaryContact,
            hasSecondPhone,
          },
        },
      };
    });
  },
};
