import React from 'react';

export const TvExtraChannel = (props: React.SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3924 7.82369V8.09369V12.7707H10.4769L17.9829 21.6267L26.2059 12.7707H20.5374V8.09369V2.67869C17.6949 2.67869 15.3924 4.98269 15.3924 7.82369ZM23.0379 8.09369H29.2494C31.3209 8.09369 32.9994 9.77219 32.9994 11.8437V26.8437H6.7494C4.6779 26.8437 2.9994 25.1652 2.9994 23.0937V8.09369H12.8919V7.82369C12.8919 3.60869 16.3209 0.179688 20.5374 0.179688H23.0379V2.67869V8.09369ZM14.2497 29.3427H21.7497C23.8212 29.3427 25.4997 31.0227 25.4997 33.0927H10.4997C10.4997 31.0227 12.1782 29.3427 14.2497 29.3427Z"
    />
  </svg>
);
