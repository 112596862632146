import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ThemeProvider, Themes } from '@vodafoneziggo/sandwich';
import ApiFactory from 'api/api-factory';
import { CheckUsernameModal } from 'components/CheckUsernameModal';
import { appSettings } from 'config/app.settings';
import { AccessStateProvider } from 'context/access-state.context';
import { AddressesProvider } from 'context/addresses.context';
import { BillingCustomerProvider } from 'context/billing-customer.context';
import { DashboardProvider } from 'context/dashboard/dashboard.context';
import { EmailProvider } from 'context/email/email.context';
import { GuruProvider } from 'context/guru/guru.context';
import { InvoicesProvider } from 'context/invoices.context';
import { MarketingSettingsProvider } from 'context/marketing-settings.context';
import { OrderDetailsProvider } from 'context/order/order.context';
import { OutageProvider } from 'context/outage/outage.context';
import { PayMeansProvider } from 'context/pay-means.context';
import { PaymentsProvider } from 'context/payments.context';
import { PrivacyProvider } from 'context/privacy.context';
import { ProductProvider } from 'context/product/product.context';
import SupportCaseProvider from 'context/support-case/support-case.provider';
import { UnbilledPhoneEventsProvider } from 'context/usage/unbilled-phone-events.context';
import { UsageProvider } from 'context/usage/usage.context';
import { ContactDetailsProvider } from 'pages/ContactDetails/contact-details.context';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';

import { AuthProvider } from './context/auth/auth.context';
import { FeatureProvider, useFeature } from './context/feature/feature.context';
import { FEATURES } from './models/features.model';
import './polyfills';
import { AppRouter } from './routes';
import GlobalStyles from './theme/global';
import api from './utils/api';

// NOTE: Using GITLAB_ENV because the normal NODE_ENV is set on production for prod, uat and acc.
if (process.env.GITLAB_ENV === 'prod') {
  Sentry.init({
    dsn: appSettings.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.02,
  });
}

ApiFactory.getInstance().setApi(api.apiV1);

export interface IMaintenancePageProps {
  children: any;
}

const MaintenancePage = ({ children }: IMaintenancePageProps) => {
  const isMaintenanceEnabled = useFeature(FEATURES.MAINTENANCE);

  if (isMaintenanceEnabled) {
    return (
      <iframe
        src={`${appSettings.ENV_MAINTENANCE_PAGE_URL}`}
        name="maintenance"
        title="maintenance"
        sandbox=""
        height="100%"
        width="100%"
        frameBorder="0"
      />
    );
  }

  return children;
};

const renderApp = () => {
  // @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
  ReactDOM.createRoot(document.getElementById('app')).render(
    <FeatureProvider>
      <AuthProvider>
        <BillingCustomerProvider>
          <MaintenancePage>
            <AccessStateProvider>
              {/* @ts-expect-error TS(2559): Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
              <ProductProvider>
                {/* @ts-expect-error TS(2559): Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
                <EmailProvider>
                  {/* @ts-expect-error TS(2559): Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
                  <ContactDetailsProvider>
                    <AddressesProvider>
                      <DashboardProvider>
                        {/* @ts-expect-error TS(2559): Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
                        <GuruProvider>
                          {/* @ts-expect-error TS(2559): Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
                          <OutageProvider>
                            <UsageProvider>
                              <UnbilledPhoneEventsProvider>
                                <PayMeansProvider>
                                  <PrivacyProvider>
                                    <MarketingSettingsProvider>
                                      <PaymentsProvider>
                                        <InvoicesProvider>
                                          <OrderDetailsProvider>
                                            {/* @ts-expect-error TS(2559): Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
                                            <SupportCaseProvider>
                                              <ThemeProvider theme={Themes.ZIGGO_WS2}>
                                                <>
                                                  <Helmet defaultTitle="Mijn Ziggo" titleTemplate="%s | Mijn Ziggo">
                                                    <meta
                                                      httpEquiv="Content-Security-Policy"
                                                      content={`
                                                        default-src ${appSettings.CSP_DEFAULT_SRC};
                                                        style-src ${appSettings.CSP_STYLE_SRC}; 
                                                        script-src ${appSettings.CSP_SCRIPT_SRC}; 
                                                        object-src ${appSettings.CSP_OBJECT_SRC}; 
                                                        font-src ${appSettings.CSP_FONT_SRC}; 
                                                        img-src ${appSettings.CSP_IMG_SRC};
                                                        frame-src ${appSettings.CSP_FRAME_SRC};
                                                        connect-src ${appSettings.CSP_CONNECT_SRC};
                                                        worker-src ${appSettings.CSP_WORKER_SRC};
                                                      `}
                                                    />
                                                  </Helmet>
                                                  <CheckUsernameModal />
                                                  <GlobalStyles />
                                                  <AppRouter />
                                                </>
                                              </ThemeProvider>
                                            </SupportCaseProvider>
                                          </OrderDetailsProvider>
                                        </InvoicesProvider>
                                      </PaymentsProvider>
                                    </MarketingSettingsProvider>
                                  </PrivacyProvider>
                                </PayMeansProvider>
                              </UnbilledPhoneEventsProvider>
                            </UsageProvider>
                          </OutageProvider>
                        </GuruProvider>
                      </DashboardProvider>
                    </AddressesProvider>
                  </ContactDetailsProvider>
                </EmailProvider>
              </ProductProvider>
            </AccessStateProvider>
          </MaintenancePage>
        </BillingCustomerProvider>
      </AuthProvider>
    </FeatureProvider>
  );
};

renderApp();
