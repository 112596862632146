import React from 'react';

export const PartyFlags = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <defs />
    <defs>
      <path
        id="party-a"
        d="M7.8 2.8a26.8 26.8 0 008.5 0l-1 2.4a60 60 0 01-3.5 7.2 85 85 0 01-4-9.6zm-7-2l.7.2c2.6 1 4.7 1.5 6 1.8L5.7 5.3l-3.6 5.2c-.4-.8-.9-4-1.4-9.8zm22.5 0L23 2.4a35 35 0 01-1.2 8.1l-.2-.3c-1.2-2-5.2-7.4-5.2-7.4a46 46 0 006.8-2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 6)">
      <mask id="party-b" fill="#fff">
        <use href="#party-a" />
      </mask>
      <use fill="currentColor" href="#party-a" />
      <g fill="currentColor" mask="url(#party-b)">
        <path d="M0-6h24v24H0z" />
      </g>
    </g>
  </svg>
);
