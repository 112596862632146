import { appSettings } from 'config/app.settings';

interface ITagPageVisitProps {
  isAuthenticated: boolean;
  billingCustomerHashedId: string;
}

export const getWindow = ({ window }: Window) => window as Window;

export const tagPageVisit = (props: ITagPageVisitProps, windowObj = getWindow(window || {})) => {
  const { isAuthenticated, billingCustomerHashedId } = props;

  try {
    const path = windowObj.location.pathname;

    // Section equals first part of url after /mijnziggo/ or uses 'dashboard' if no path is available
    const section = path.replace(appSettings.ENV_PUBLIC_URL, '').split('/')[0] || 'dashboard';

    windowObj._ddm.trigger('routeChange.ready', {
      data: {
        page: {
          logged_in: isAuthenticated,
          type: section,
        },
        user: {
          products: {},
          other: {},
          relevant: {},
          items: {},
          zcid: billingCustomerHashedId,
        },
      },
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export interface IClickToggleMeta {
  technicalName: string;
  meaningfulName: string;
}

export const clickToggle = (name: string, value: boolean, meta?: IClickToggleMeta) => {
  try {
    window._ddm.trigger('clickToggle', {
      data: {
        event: 'click.toggle',
        type: 'toggle',
        description: name,
        status: value ? 'on' : 'off',
      },
      component: {
        technical_name: meta?.technicalName,
        meaningful_name: meta?.meaningfulName,
        location: window.location.pathname,
        id: undefined,
        type: 'toggle',
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while sending clickToggle event');
  }
};

export const orderStepTracker = (step: string, isAuthenticated: boolean, billingCustomerHashedId?: string) => {
  try {
    const path = window.location.pathname;
    window._ddm.trigger('order.step', {
      data: {
        page: {
          logged_in: isAuthenticated,
          type: path.replace(appSettings.ENV_PUBLIC_URL, '').trim().split('/')[0] || 'dashboard',
          path: path.replace(appSettings.ENV_PUBLIC_URL, '') || 'dashboard',
          step,
        },
        user: {
          zcid: billingCustomerHashedId,
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while sending order step event');
  }
};
