import { ApiResponse, query } from './api';
import * as Q from './television.query';
import * as T from './television.types';

export interface TelevisionApiInterface {
  getTvBenefits(billingCustomerId: string): ApiResponse<T.TvBenefit[]>;
  getAssignedTVBenefit(billingCustomerId: string): ApiResponse<T.AssignedBenefit>;
  getPendingTvBenefitName(billingCustomerId: string): ApiResponse<T.PendingTvBenefitName>;
  setTvBenefit(billingCustomerId: string, televisionPackageId: string): ApiResponse<{ success: boolean }>;
}

export const TelevisionApi: TelevisionApiInterface = {
  getTvBenefits(billingCustomerId: string) {
    return query({
      query: Q.getTelevisionBenefitsQuery,
      variables: { billingCustomerId },
      name: 'getTelevisionBenefits',
    }).then(({ getTelevisionBenefits }) => ({
      data: getTelevisionBenefits,
    }));
  },
  getAssignedTVBenefit(billingCustomerId: string) {
    return query({
      query: Q.getAssignedTelevisionBenefitQuery,
      variables: { billingCustomerId },
      name: 'getAssignedTelevisionBenefit',
    }).then(({ getAssignedTelevisionBenefit }) => ({
      data: getAssignedTelevisionBenefit,
    }));
  },
  getPendingTvBenefitName(billingCustomerId: string) {
    return query({
      query: Q.getPendingTvBenefitNameQuery,
      variables: { billingCustomerId },
      name: 'getPendingTvBenefitName',
    }).then(({ getPendingTvBenefitName }) => ({
      ...getPendingTvBenefitName,
    }));
  },
  setTvBenefit(billingCustomerId: string, televisionPackageId: string) {
    return query({
      query: Q.putAssignedTelevisionBenefitQuery,
      variables: { billingCustomerId, televisionPackageId },
      name: 'putAssignedTelevisionBenefit',
    }).then(({ putAssignedTelevisionBenefit }) => ({
      ...putAssignedTelevisionBenefit,
    }));
  },
};
