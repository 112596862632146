import React from 'react';

export const Vodafone = (props: React.SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6806 1.73834C22.4203 2.43134 19.8283 5.46884 19.8396 8.91584C19.8396 9.03059 19.8508 9.15209 19.8621 9.20834C25.3431 10.5448 27.8316 13.8523 27.8451 18.4266C27.8608 23.0031 24.2473 28.0183 18.1408 28.0363C13.1976 28.0521 8.05184 23.8311 8.02934 17.0563C8.01359 12.5721 10.4323 8.25659 13.5238 5.69834C16.5388 3.20084 20.6676 1.59884 24.4161 1.58759C24.8638 1.58759 25.3183 1.62584 25.6311 1.71359C23.3113 0.626843 20.7328 0.00134277 18.0013 0.00134277C8.05859 0.00134277 0.00134277 8.06084 0.00134277 18.0013C0.00134277 27.9418 8.05859 36.0013 18.0013 36.0013C27.9418 36.0013 36.0013 27.9418 36.0013 18.0013C36.0013 10.8103 31.7736 4.62284 25.6806 1.73834Z"
    />
  </svg>
);
