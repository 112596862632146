import React, { SVGProps } from 'react';

export const CalendarTimeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7498 18.5003C19.7498 18.2243 19.5258 18.0003 19.2498 18.0003H16.7498V13.5003C16.7498 13.2243 16.5258 13.0003 16.2498 13.0003C15.9738 13.0003 15.7498 13.2243 15.7498 13.5003V19.0003H19.2498C19.5258 19.0003 19.7498 18.7763 19.7498 18.5003V18.5003ZM22.2498 18.0003C22.2498 21.0373 19.7878 23.5003 16.7498 23.5003C13.7118 23.5003 11.2498 21.0373 11.2498 18.0003C11.2498 14.9623 13.7118 12.5003 16.7498 12.5003C19.7878 12.5003 22.2498 14.9623 22.2498 18.0003V18.0003ZM19.7498 7.00031H1.74979V4.00031H4.74979V2.00031H6.74979V4.00031H14.7498V2.00031H16.7498V4.00031C18.4068 4.00031 19.7498 5.34331 19.7498 7.00031V7.00031ZM6.74979 13.0003H4.74979C4.19779 13.0003 3.74979 12.5523 3.74979 12.0003V10.0003H5.74979C6.30279 10.0003 6.74979 10.4473 6.74979 11.0003V13.0003ZM6.74979 18.0003H4.74979C4.19779 18.0003 3.74979 17.5523 3.74979 17.0003V15.0003H5.74979C6.30279 15.0003 6.74979 15.4473 6.74979 16.0003V18.0003ZM9.24979 10.0003H11.2498C11.8028 10.0003 12.2498 10.4473 12.2498 11.0003V13.0003H10.2498C9.69779 13.0003 9.24979 12.5523 9.24979 12.0003V10.0003ZM10.2498 18.0003C9.69779 18.0003 9.24979 17.5523 9.24979 17.0003V15.0003H10.9898C11.7728 13.5023 13.1198 12.3473 14.7498 11.8173V10.0003H16.7498C17.3028 10.0003 17.7498 10.4473 17.7498 11.0003V11.5853C18.4608 11.6953 19.1348 11.9173 19.7498 12.2393V8.0003H1.74979V17.0003C1.74979 18.6563 3.09279 20.0003 4.74979 20.0003H10.5678C10.3638 19.3693 10.2498 18.6973 10.2498 18.0003V18.0003Z"
      fill="#1C1D1E"
    />
  </svg>
);
