import { ApiResponse, rest } from './api';
import { IdealPaymentStatusEnum } from './ideal-payment-status.types';

interface IdealPaymentStatusInterface {
  getIdealPaymentStatus(transactionId: string): ApiResponse<{ ideal_status: IdealPaymentStatusEnum }>;
}

export const IdealPaymentStatusApi: IdealPaymentStatusInterface = {
  getIdealPaymentStatus(transactionId: string) {
    return rest({
      url: '/ideal-payment-status',
      params: {
        transactionId,
      },
      method: 'GET',
      authenticated: false,
    });
  },
};
