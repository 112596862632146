import styled, { css, rem, screenTabletLandscapeAndBigger, screenTabletPortraitAndSmaller, spacingBase } from 'theme';

const container = css`
  width: 100%;
  max-width: ${rem(1000)};
  margin: 0 auto;
  padding: calc(${() => spacingBase} * 3);
`;

export const Error = styled('div')`
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  ${screenTabletLandscapeAndBigger(css`
    font-size: ${rem(20)};
  `)}

  a {
    color: ${(p) => p.theme.colors.primary};
    text-decoration: underline;
  }
`;

export const ErrorHeader = styled('div')`
  width: 100%;
  padding: calc(${() => spacingBase} * 3);
  padding-bottom: 0;
`;

export const ErrorFooter = styled('div')`
  background-color: ${(p) => p.theme.colors.gray100};
  text-align: center;

  ${screenTabletPortraitAndSmaller(css`
    text-align: left;
  `)}
`;

export const Support = styled('div')`
  ${container};

  ${screenTabletPortraitAndSmaller(css`
    line-height: 2.5;
  `)}
`;

export const SupportLinks = styled('div')`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;

  ${() =>
    screenTabletLandscapeAndBigger(css`
      margin: calc(${spacingBase} * 4) 0;
      flex-direction: row;
    `)}
`;

export const ErrorContent = styled('div')`
  ${container};
  background-color: ${(p) => p.theme.colors.white};
  display: flex;

  ${screenTabletPortraitAndSmaller(css`
    flex-direction: column-reverse;
  `)}
`;

export const Button = styled('button')`
  cursor: pointer;
`;

export const Img = styled('div')`
  order: 1;
  text-align: center;
`;

export const Message = styled('div')`
  text-align: left;
  order: 0;

  h1 {
    font-size: ${rem(45)};
    margin-bottom: ${rem(26)};

    ${screenTabletPortraitAndSmaller(css`
      font-size: ${rem(35)};
      margin-bottom: ${rem(20)};
    `)}
  }

  h3 {
    font-size: ${rem(25)};
    color: ${(p) => p.theme.colors.grayL30};
    margin-bottom: ${rem(50)};

    ${screenTabletPortraitAndSmaller(css`
      font-size: ${rem(20)};
      margin-bottom: ${rem(15)};
      line-height: ${rem(30)};
    `)}
  }
`;
