import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { Button } from 'components/Button';
import { Input } from 'components/Form';
import { Modal } from 'components/Modal';
import { ModalButtons } from 'components/Modal/modal.styles';
import { SectionHeader } from 'components/SectionHeader';
import React, { useState } from 'react';
import { ContentUtil } from 'utils';

import IOutageUnsubscribeFormProps from './Interface/IOutageUnsubscribeFormProps';
import { content } from './outage-overview.content';
import * as Styles from './outage-overview.styles';

const contentUtil = new ContentUtil({ content, componentName: 'OutageOverview' });

const OutageUnsubscribeForm = (props: IOutageUnsubscribeFormProps) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const confirm = async () => {
    setShowConfirmation(true);
  };

  const submit = async (): Promise<void> => {
    setShowConfirmation(false);
    props.onSubmit(props.subscribedOutage);
  };

  const renderNumberInputs = () => {
    // @ts-expect-error TS(6133): 'input' is declared but its value is never read.
    return props.subscribedOutage?.telephoneNumbers?.map((input, index) => (
      <Input
        key={`number-input-${index}`}
        label={contentUtil.translateToString('phoneNumberLabel')}
        name={`telephoneNumbers[${index}]`}
        type="tel"
        isDisabled
      />
    ));
  };

  return (
    <>
      <Styles.Form
        onSubmit={confirm}
        hideSubmitButton
        hideCancelButton
        initialValues={{
          telephoneNumbers: props.subscribedOutage.telephoneNumbers?.map((value) =>
            value.replace(/^([+]?(00)?31)/g, '0')
          ),
        }}
        render={() => (
          <>
            {renderNumberInputs()}

            <div className="form-button">
              <Button type="submit" buttonVariant={ButtonVariant.DARK} testid="form-submit-button">
                {contentUtil.translateToString('unsubscribe')}
              </Button>
            </div>
          </>
        )}
      />

      {showConfirmation && (
        <Modal onClose={() => setShowConfirmation(false)}>
          {(closeModal) => (
            <section>
              <SectionHeader>{contentUtil.translateToString('unsubscribeModal.header')}</SectionHeader>
              <p>{contentUtil.translateToString('unsubscribeModal.content')}</p>

              <ModalButtons>
                <Button onClick={submit}>{contentUtil.translateToString('unsubscribeModal.confirm')}</Button>
                <Button outline onClick={closeModal} buttonVariant={ButtonVariant.DARK}>
                  {contentUtil.translateToString('unsubscribeModal.cancel')}
                </Button>
              </ModalButtons>
            </section>
          )}
        </Modal>
      )}
    </>
  );
};

export default OutageUnsubscribeForm;
