import { ChevronIcon } from 'assets/icons';
import { ErrorMessage } from 'components/Form/components/ErrorMessage';
import * as SField from 'components/Form/components/Field/field.styles';
import { composeValidators } from 'components/Form/validate';
import React from 'react';
import { Field } from 'react-final-form';
import { ContentUtil } from 'utils/content';
import randomId from 'utils/random-id';

import { content } from './select.content';
import * as S from './select.styles';

const contentUtil = new ContentUtil({ content, componentName: 'Form > Select' });

interface SelectOptionsProps {
  defaultSelectValue?: boolean;
  defaultSelectLabel?: string;
  options: { value: string; label: string; default?: boolean; id?: string }[];
}

export interface SelectProps extends SelectOptionsProps {
  name: string;
  label?: string;
  hideLabel?: boolean;
  id?: string;
  onChange?: Function;
  disabled?: boolean;
  isRequired?: boolean;
  validate?: Function[];
  variant?: string;
  testid?: string;
}

const SelectOptions = (props: SelectOptionsProps) => {
  return (
    <React.Fragment>
      {props.defaultSelectValue && (
        <option value="" disabled>
          {props.defaultSelectLabel}
        </option>
      )}
      {props.options &&
        props.options.length &&
        props.options.map((option, index) => (
          <option value={option.value} key={index} id={option.id}>
            {option.label}
          </option>
        ))}
    </React.Fragment>
  );
};

export class Select extends React.Component<SelectProps, {}> {
  onFieldChange = (e: any, FFonFieldChange: any) => {
    FFonFieldChange(e);
    if (typeof this.props.onChange === 'function') this.props.onChange(e);
  };

  render() {
    const {
      name,
      variant,
      options,
      label,
      hideLabel,
      isRequired,
      validate,
      id = randomId(),
      disabled,
      defaultSelectValue = true,
      defaultSelectLabel = contentUtil.translateToString('defaultOption'),
      testid,
    } = this.props;

    return (
      <React.Fragment>
        {label && (
          <S.Label>
            {label} {isRequired && <span> *</span>}
          </S.Label>
        )}
        <Field
          name={name}
          validate={validate ? composeValidators(validate) : undefined}
          render={({ input, meta }) => {
            const select = (
              <S.Select
                {...input}
                data-testid={testid}
                variant={variant}
                aria-label={hideLabel ? label : undefined}
                onChange={(e) => this.onFieldChange(e, input.onChange)}
                disabled={disabled}
                id={id}>
                <SelectOptions
                  defaultSelectValue={defaultSelectValue}
                  defaultSelectLabel={defaultSelectLabel}
                  options={options}
                />
              </S.Select>
            );

            return (
              <React.Fragment>
                {variant === 'sort' && select}
                {variant !== 'sort' && (
                  <SField.Field>
                    {select}
                    <S.Chevron>
                      <ChevronIcon />
                    </S.Chevron>
                  </SField.Field>
                )}
                {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }
}
