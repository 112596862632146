import React from 'react';

export const Telephony = (props: React.SVGAttributes<SVGElement> & { title?: string }) => (
  <svg width="192" height="192" viewBox="0 0 192 192" {...props}>
    {props.title ? <title>{props.title}</title> : null}
    <g fill="none" fillRule="evenodd">
      <path stroke="#6D6E71" strokeLinecap="round" strokeWidth="3.87" d="M43.5 142.93h106.02" />
      <path
        fill="#F1F2F2"
        d="M114.62 99.23H79.26a16.7 16.7 0 0 0-16.77 16.63v33.27h68.89v-33.27a16.7 16.7 0 0 0-16.76-16.63"
      />
      <path
        fill="#F1F2F2"
        d="M71.9 102.85v16.93c0 11.6 9.47 21 21.16 21h28.92v-37.93c0-1.17-.95-2.12-2.14-2.12h-45.8c-1.19 0-2.15.95-2.15 2.12"
      />
      <path
        stroke="#575756"
        strokeWidth="3.87"
        d="M71.9 102.85v16.93c0 11.6 9.47 21 21.16 21h28.92v-37.93c0-1.17-.95-2.12-2.14-2.12h-45.8c-1.19 0-2.15.95-2.15 2.12z"
      />
      <path
        stroke="#6D6E71"
        strokeWidth="3.87"
        d="M118.83 99.23H75.04a12.5 12.5 0 0 0-12.55 12.45v37.45h68.9v-37.45a12.5 12.5 0 0 0-12.56-12.45z"
      />
      <path
        fill="#F1F2F2"
        d="M92.54 138.97a18.58 18.58 0 0 1-18.64-18.49v-75.6h27.44c10.27 0 18.63 8.3 18.63 18.49v75.6H92.54z"
      />
      <path
        fill="#6D6E71"
        d="M101.34 43H72v77.48a20.47 20.47 0 0 0 20.54 20.37h29.33V63.37A20.48 20.48 0 0 0 101.34 43m0 3.76c9.24 0 16.74 7.44 16.74 16.6v73.73H92.54a16.68 16.68 0 0 1-16.74-16.6V46.75h25.54M88.36 54.66h17.16"
      />
      <path stroke="#6D6E71" strokeLinecap="round" strokeWidth="4.7" d="M88.36 54.66h17.16" />
      <path fill="#DEE0D4" opacity=".3" d="M75.8 106.18l31.23-59.43H75.8z" />
      <path
        fill="#D6DDE6"
        d="M113.93 91.27h-21.6c-6.84 0-12.38-5.5-12.38-12.3V61.1h21.6c6.83 0 12.38 5.5 12.38 12.29v17.89z"
      />
      <path fill="#DEE0D4" d="M104.6 61.09c5.22 1.3 4.85 7.13 4.85 7.13v23.06h4.48v-25s-1.15-5.2-5.24-5.2h-4.1z" />
      <path
        stroke="#6D6E71"
        strokeWidth="2.82"
        d="M113.93 91.27h-21.6c-6.84 0-12.38-5.5-12.38-12.3V61.1h21.6c6.83 0 12.38 5.5 12.38 12.29v17.89z"
      />
      <path
        fill="#6D6E71"
        d="M86.47 104.51h-.4c-1.99 0-3.6-1.6-3.6-3.59v-.38a3.6 3.6 0 0 1 3.6-3.6h.4c2 0 3.61 1.62 3.61 3.6v.38a3.6 3.6 0 0 1-3.61 3.6m10.66 0h-.39c-2 0-3.61-1.61-3.61-3.6v-.38a3.6 3.6 0 0 1 3.61-3.6h.4c1.99 0 3.6 1.62 3.6 3.6v.38a3.6 3.6 0 0 1-3.6 3.6m10.66 0h-.4c-1.99 0-3.61-1.61-3.61-3.6v-.38a3.6 3.6 0 0 1 3.62-3.6h.39c2 0 3.61 1.62 3.61 3.6v.38a3.6 3.6 0 0 1-3.61 3.6m-21.33 9.68h-.4c-1.99 0-3.6-1.6-3.6-3.59v-.39a3.6 3.6 0 0 1 3.6-3.58h.4c2 0 3.61 1.6 3.61 3.58v.39a3.6 3.6 0 0 1-3.61 3.59m10.66 0h-.39c-2 0-3.61-1.6-3.61-3.59v-.39a3.6 3.6 0 0 1 3.61-3.58h.4c1.99 0 3.6 1.6 3.6 3.58v.39a3.6 3.6 0 0 1-3.6 3.59m10.66 0h-.4c-1.99 0-3.61-1.6-3.61-3.59v-.39a3.6 3.6 0 0 1 3.62-3.58h.39c2 0 3.61 1.6 3.61 3.58v.39a3.6 3.6 0 0 1-3.61 3.59m-21.33 9.69h-.4c-1.99 0-3.6-1.61-3.6-3.6v-.38a3.6 3.6 0 0 1 3.6-3.59h.4c2 0 3.61 1.6 3.61 3.59v.39a3.6 3.6 0 0 1-3.61 3.59m10.66 0h-.39c-2 0-3.61-1.61-3.61-3.6v-.38a3.6 3.6 0 0 1 3.61-3.59h.4c1.99 0 3.6 1.6 3.6 3.59v.39a3.6 3.6 0 0 1-3.6 3.59m10.66 0h-.4c-1.99 0-3.61-1.61-3.61-3.6v-.38a3.6 3.6 0 0 1 3.62-3.59h.39c2 0 3.61 1.6 3.61 3.59v.39a3.6 3.6 0 0 1-3.61 3.59"
      />
    </g>
  </svg>
);
