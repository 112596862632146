import { OrderApi, OrderStatus } from 'api';
import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { DateUnit, dateUtils } from 'utils/date';

import { createBaseContext } from '../base/create-base-context';

const dateYearAgo = dateUtils.subtract(new Date(), 1, DateUnit.YEARS);

export const { Provider: OrderProvider, Context: OrderContext } = createBaseContext({
  read: {
    orderWidget: {
      eager: false,
      // prefill the params, can be overriden when called but as it is eager fill now.
      // The new Date.toIsoString split results in date like : 2012-10-06
      execute: (bcid: string, limit = 3, dateFrom: string = dateYearAgo.toISOString().split('T')[0]) =>
        OrderApi.getOrders(bcid, limit, dateFrom).then((response) => response.data),
    },
    orders: {
      execute: (bcid: string, limit: number, dateFrom: string) =>
        OrderApi.getOrders(bcid, limit, dateFrom).then((response) => response.data),
    },
  },
  write: {},
});

export const { Provider: OrderDetailsProvider, Context: OrderDetailsContext } = createBaseContext({
  read: {
    orderDetails: {
      execute: async (bcid: string, orderId: string) => {
        const { data } = await OrderApi.getOrder(bcid, orderId);
        try {
          if (!data.pointOfNoReturnPassed) {
            const { data: validDateList } = await OrderApi.getOrderValidDateList(bcid, orderId);
            return { ...data, ...validDateList };
          }
        } catch (err) {
          // Ignoring error for now as a hotfix
        }

        return data;
      },
    },
  },
  write: {
    updateActivationDate: {
      execute: async (bcid: string, orderId: string, activationDate: string) => {
        const { data } = await OrderApi.mutateActivationDate(bcid, orderId, activationDate);
        return data;
      },
    },
    updateDeliveryAddress: {
      execute: async (bcid: string, orderId: string, deliveryAddressId: string) => {
        const { data } = await OrderApi.mutateDeliveryAddress(bcid, orderId, deliveryAddressId);
        return data;
      },
    },
  },
});

export const OpenOrderContext = React.createContext({ hasOpenOrder: false, isLoading: true });
export const OpenOrderProvider = ({ children }: PropsWithChildren<any>) => {
  const orderContext = useContext(OrderContext);
  const { value: orders, isLoading, execute } = orderContext.read.orderWidget;

  useEffect(() => {
    execute();
  }, []);

  const hasOpenOrder = Boolean(orders && orders.find((o) => o.orderStatus === OrderStatus.OPEN));
  return <OpenOrderContext.Provider value={{ hasOpenOrder, isLoading }}>{children}</OpenOrderContext.Provider>;
};
