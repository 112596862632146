import React, { SVGProps } from 'react';

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.649514,18.36 C22.9011218,16.485 23.6334774,14.232 23.6334774,11.808 C23.630476,5.289 18.3419076,5.32907052e-15 11.8167387,5.32907052e-15 C5.29156982,5.32907052e-15 0,5.289 0,11.811 C0,18.333 5.29156982,23.622 11.8167387,23.622 C14.2269092,23.622 16.4719995,22.899 18.3389062,21.66 L26.0076304,29.325 C26.9080677,30.225 28.411798,30.225 29.3122353,29.325 C30.2456886,28.392 30.2126726,26.922 29.3122353,26.022 L21.649514,18.36 Z M3.78183663,11.811 C3.78183663,7.377 7.37758288,3.78 11.8167387,3.78 C16.2528931,3.78 19.8516408,7.374 19.8516408,11.811 C19.8516408,16.248 16.2528931,19.839 11.8167387,19.839 C7.37758288,19.839 3.78183663,16.245 3.78183663,11.811 Z"
    />
  </svg>
);
