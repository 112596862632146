import { Icon, Button as SandwichButton } from '@vodafoneziggo/sandwich';
import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import React from 'react';
import { NavLink } from 'react-router-dom';

export interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  /* to is navlink */
  to?: string;
  /* href is normal link */
  href?: string;
  /* onClick is button, also default */
  onClick?(e?: React.MouseEvent): void;
  type?: 'button' | 'submit' | 'reset';
  name?: string;
  testid?: string;
  className?: string;
  outline?: boolean;
  external?: boolean;
  isFullWidth?: boolean;
  buttonVariant?: ButtonVariant;
  rightIcon?: Icon;
  leftIcon?: Icon;
  isLeftAligned?: boolean;
}

export const Button = ({
  children,
  disabled,
  type,
  to,
  href,
  name,
  testid,
  onClick,
  outline,
  external,
  isFullWidth,
  buttonVariant = ButtonVariant.PRIMARY,
  rightIcon,
  leftIcon,
  isLeftAligned,
  ...rest
}: // ...rest
ButtonProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!to && onClick) onClick();
  };

  let as = 'button' as any;

  if (href) {
    as = 'a';
  } else if (to) {
    as = NavLink as any;
  }

  return (
    <SandwichButton
      as={as}
      isFullWidth={isFullWidth}
      target={external ? '_blank' : '_self'}
      isOutlined={outline}
      isLeftAligned={isLeftAligned}
      name={name}
      href={href}
      to={to}
      type={type || 'button'}
      testId={testid}
      onClick={handleClick}
      isDisabled={disabled}
      variant={buttonVariant}
      {...rest}>
      {leftIcon && <Icon icon={leftIcon} width="14" height="14" />}
      {children}
      {rightIcon && <Icon icon={rightIcon} width="14" height="14" />}
    </SandwichButton>
  );
};
