import React from 'react';

export const IDeal = () => (
  <svg width="66" height="58" viewBox="0 0 66 58" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path
        d="M.463.472h33.741c22.901 0 31.679 12.506 31.679 28.282 0 18.917-12.258 28.409-31.679 28.409H.463V.472zm3.271 3.271v50.151h30.47c18.396 0 28.405-8.572 28.405-25.14 0-17.027-10.85-25.012-28.405-25.012H3.734v.001z"
        fill="#000"
      />
      <path fill="#000" d="M7.822 32.442h9.754v17.36H7.822z" />
      <circle fill="#000" cx="12.698" cy="23.695" r="6.062" />
      <path
        d="M38.031 26.531v2.811h-6.95v-11.28h6.728v2.81h-3.918v1.306h3.705v2.811h-3.705v1.543l4.14-.001zm1.216 2.813l3.401-11.286h3.998l3.4 11.286h-2.925l-.638-2.183h-3.676l-.639 2.183h-2.921zm4.384-4.991h2.034l-.933-3.188h-.17l-.931 3.188zm7.828-6.294h2.812v8.473h4.162C57.291 11.158 45.199 7.836 34.205 7.836H22.488v10.229h1.734c3.162 0 5.125 2.144 5.125 5.594 0 3.56-1.916 5.685-5.125 5.685h-1.734V49.81h11.718c17.866 0 24.088-8.298 24.308-20.468h-7.055V18.059zm-28.973 2.816v5.657h1.736c1.203 0 2.315-.348 2.315-2.874 0-2.467-1.237-2.783-2.315-2.783h-1.736z"
        fill="#D50072"
      />
    </g>
  </svg>
);
