import React from 'react';

export const CloseIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg width="25px" height="25px" viewBox="0 0 162 125" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <rect id="path-1" x="0" y="0" width="162" height="125" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlinkHref="#path-1" />
      </mask>
      <path d="M15.5,0.5 L146.5,124.5" id="Line" stroke="currentColor" strokeWidth="21" mask="url(#mask-2)" />
      <path
        d="M145.456602,5.68434189e-14 L15,124.571342"
        id="Line"
        stroke="currentColor"
        strokeWidth="21"
        mask="url(#mask-2)"
      />
    </g>
  </svg>
);
