import React, { SVGProps } from 'react';

export const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox={`0 0 ${props?.height || 24} ${props?.height || 24}`} {...props}>
    <defs>
      <path
        id="calendar-icon-a"
        d="M3.5 2v1a1.5 1.5 0 003 0V2h7v1a1.5 1.5 0 003 0V2A4 4 0 0120 6v14H4a4 4 0 01-4-4V2h3.5zM5 14H2v3a1 1 0 001 1h3v-3a1 1 0 00-1-1zm6 0H8v3a1 1 0 001 1h3v-3a1 1 0 00-1-1zm6 0h-3v3a1 1 0 001 1h3v-3a1 1 0 00-1-1zM5 8H2v3a1 1 0 001 1h3V9a1 1 0 00-1-1zm6 0H8v3a1 1 0 001 1h3V9a1 1 0 00-1-1zm6 0h-3v3a1 1 0 001 1h3V9a1 1 0 00-1-1zM5 0a1 1 0 011 1v2a1 1 0 11-2 0V1a1 1 0 011-1zm10 0a1 1 0 011 1v2a1 1 0 01-2 0V1a1 1 0 011-1z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <mask id="calendar-icon-b" fill="#fff">
        <use href="#calendar-icon-a" />
      </mask>
      <use href="#calendar-icon-a" />
      <g fill="currentColor" mask="url(#calendar-icon-b)">
        <path d="M-2-2h24v24H-2z" />
      </g>
    </g>
  </svg>
);
