import { LoaderIcon } from 'assets/icons';
import Image from 'assets/img/ziggo-error.gif';
import { Link } from 'components/Link';
import React from 'react';
import { ContentUtil } from 'utils';

import { content } from './splash-screen.content';
import * as S from './splash-screen.styles';

const contentUtil = new ContentUtil({ content, componentName: 'ProgressTracker' });
const usps = ['usp1', 'usp2', 'usp3'];

interface SplashScreenProps {
  hasError?: boolean;
  errorType?: string | null;
}

export const SplashScreen = ({ hasError, errorType }: SplashScreenProps) => {
  return (
    <S.SplashScreenWrapper>
      {hasError && (
        <S.Card data-testid="error-card">
          <S.CardTitle>{contentUtil.translateToString('errorCardTitle')}</S.CardTitle>
          <p>
            Fout code: <span data-testid="error-code">{errorType}</span>
          </p>
          <img src={Image} alt={contentUtil.translateToString('imageAlt')} />
          <div>
            <Link href="/" inline>
              {contentUtil.translateToString('errorCardLink')}
            </Link>
          </div>
        </S.Card>
      )}
      {!hasError && (
        <>
          <LoaderIcon />
          <S.Header data-testid="loading-header">{contentUtil.translateToString('isLoading')}</S.Header>
          <S.List>
            {usps.map((usp, index) => (
              <S.ListItem key={index} delay={index}>
                <S.Check /> {contentUtil.translateToString(usp)}
              </S.ListItem>
            ))}
          </S.List>
        </>
      )}
    </S.SplashScreenWrapper>
  );
};
