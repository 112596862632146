import { CheckIcon } from 'assets/icons';
import { Card as CardComponent } from 'components/Card';
import styled, { css, gapSize, rem, screenMobilePlusAndBigger, spacingBase, tablet } from 'theme';

export const SplashScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${() => gapSize(7)} ${() => spacingBase};
  align-items: center;
  background-color: ${(p) => p.theme.colors.gray100};

  ${tablet(css`
    padding-top: ${() => gapSize(18)};
  `)};
`;

export const Header = styled.h2`
  margin: ${() => gapSize(5)} 0;
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: ${() => gapSize(2)};
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0 0 ${rem(15)} 0 #d3d6d9;
  border-radius: ${rem(9)};

  ${screenMobilePlusAndBigger(css`
    padding: ${() => gapSize(3)} ${() => gapSize(6)};
    width: unset;
  `)}

  ${tablet(css`
    width: 600px;
  `)}
`;

export const ListItem = styled.li<{ delay: number }>`
  animation: fade-in 0.5s linear;
  animation-fill-mode: both;
  animation-delay: ${(p) => p.delay || 0}s;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Check = styled(CheckIcon)`
  color: #5daa1a;
  vertical-align: middle;
  width: ${rem(20)};
  height: ${rem(20)};
  margin-right: ${() => spacingBase};

  ${screenMobilePlusAndBigger(css`
    margin-right: ${() => gapSize(2)};
  `)}
`;

export const Card = styled(CardComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 320px;
  width: 90%;

  padding: ${() => gapSize(3)} ${() => gapSize(6)};
  text-align: center;

  ${screenMobilePlusAndBigger(css`
    width: 460px;
  `)}

  ${tablet(css`
    width: 600px;
  `)}
`;

export const CardTitle = styled.h3`
  em {
    font-style: normal;
  }
`;
