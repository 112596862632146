import { UserIcon } from 'assets/icons';
import GenericModal from 'components/GenericModal/GenericModal';
import { appSettings } from 'config/app.settings';
import { AuthContext } from 'context/auth/auth.context';
import React, { useContext } from 'react';
import { ContentUtil } from 'utils';
import { isValidEmail } from 'utils/valid-email.util';

import { content } from './check-username-modal.content';
import * as S from './check-username-modal.styles';

const contentUtil = new ContentUtil({ content, componentName: 'CheckUsernameModal' });

export const CheckUsernameModal = () => {
  const { userName } = useContext(AuthContext);

  if (!userName || isValidEmail(userName)) return null;

  return (
    <GenericModal
      title={
        <S.Title>
          <UserIcon />
          {contentUtil.translateToString('title')}
        </S.Title>
      }
      primaryButtonText={contentUtil.translateToString('primaryButtonText')}
      href={`${appSettings.ENV_ACCOUNT_URL}/account/verander-login`}
      external
      secondaryButtonText={contentUtil.translateToString('secondaryButtonText')}>
      <>
        <h3>{contentUtil.translateToString('body.title')}</h3>
        <S.Content>{contentUtil.translateHtml('body.content', { userName })}</S.Content>
        <S.ListTitle>{contentUtil.translateToString('body.list.title')}</S.ListTitle>
        <S.List>
          <li>{contentUtil.translateToString('body.list.benefit1')}</li>
          <li>{contentUtil.translateToString('body.list.benefit2')}</li>
          <li>{contentUtil.translateToString('body.list.benefit3')}</li>
        </S.List>
      </>
    </GenericModal>
  );
};
