import React, { SVGProps } from 'react';

export const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 1.50586C8.88765 1.50586 6.36615 4.02886 6.36615 7.13986C6.36615 10.2509 8.88765 12.7739 12.0002 12.7739C15.1112 12.7739 17.6342 10.2509 17.6342 7.13986C17.6342 4.02886 15.1112 1.50586 12.0002 1.50586V1.50586ZM22.2437 22.5059H1.75665V20.4164C1.75665 17.6099 4.03215 15.3359 6.83865 15.3359H17.1617C19.9682 15.3359 22.2437 17.6099 22.2437 20.4164V22.5059Z"
      fill="#1C1D1E"
    />
  </svg>
);
