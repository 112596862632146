import {
  BillingCustomer,
  BillingCustomerApi,
  BillingCustomerTypeEnum,
  ContactApi,
  NameCorrection,
  UpdateContact,
  UpdateContactResponse,
} from 'api';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { cloneDeep } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';

export interface IContactDetailsContext {
  isLoading: boolean;
  hasError: boolean;
  isSuccess: boolean;
  contactDetails: BillingCustomer | null;
  customerType: BillingCustomerTypeEnum | undefined;
  updateContactDetails(contactId: string, contactDetails: UpdateContact): Promise<boolean>;
  sendCorrectDetails(contactId: string, payload: NameCorrection): Promise<boolean>;
}

interface ContactDetailsProviderProps {
  children: React.ReactNode;
}

const ContactDetailsContext = createContext<IContactDetailsContext | undefined>(undefined);

const useContactDetailsContext = () => useContext(ContactDetailsContext) as IContactDetailsContext;

const ContactDetailsProvider: React.FC = ({ children }: ContactDetailsProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [contactDetails, setContactDetails] = useState<BillingCustomer | null>(null);
  const [customerType, setCustomerType] = useState<BillingCustomerTypeEnum | undefined>(undefined);
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (billingCustomerContext.completed) getContactDetails();
  }, [billingCustomerContext.completed]);

  const updateContactDetails = (contactId: string, newContactDetails: UpdateContact): Promise<boolean> => {
    const { activeBcId } = billingCustomerContext;

    return ContactApi.updateContact(activeBcId, contactId, newContactDetails).then((response) => {
      const actualResponse = response as never as UpdateContactResponse;
      const result = actualResponse.putBillingCustomer.success;
      setSuccess(result);

      if (result) {
        const updatedContactDetails = cloneDeep(contactDetails);

        if (updatedContactDetails) {
          updatedContactDetails.primaryContact.email = newContactDetails.email;
          updatedContactDetails.primaryContact.phone = newContactDetails.phone;
          updatedContactDetails.primaryContact.mobile = newContactDetails.mobile;

          setContactDetails(updatedContactDetails);
        }
      }
      return actualResponse.putBillingCustomer.success;
    });
  };

  const sendCorrectDetails = async (contactId: string, payload: NameCorrection) => {
    const { activeBcId } = billingCustomerContext;

    try {
      const result = await ContactApi.correctContactDetails(activeBcId, contactId, payload);
      getContactDetails();
      return result.putContactDetails.success;
    } catch (error) {
      return false;
    }
  };

  const getContactDetails = async (): Promise<void> => {
    const { activeBcId, isLoading } = billingCustomerContext;

    if (isLoading || !activeBcId) return;

    setLoading(true);
    setHasError(false);

    try {
      const response = await BillingCustomerApi.getBillingCustomer(activeBcId);

      const contactDetails: BillingCustomer = response.data;

      setLoading(false);
      setContactDetails(contactDetails);
      setCustomerType(contactDetails?.type);
    } catch (e) {
      setLoading(false);
      setHasError(true);
    }
  };

  return (
    <ContactDetailsContext.Provider
      value={{
        hasError,
        isSuccess,
        customerType,
        contactDetails,
        isLoading,
        updateContactDetails,
        sendCorrectDetails,
      }}>
      {children}
    </ContactDetailsContext.Provider>
  );
};

export { ContactDetailsContext, ContactDetailsProvider, useContactDetailsContext };
